import React, { useState } from "react";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import SupervisorMenu from "../menu/supervisor.component";
import CoordinationMenu from "../menu/coordination.component";
import Form from "react-bootstrap/Form";
import logo from "../../logo.svg";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import AppraiserMenu from "../menu/appraiser.component";

export default function Authentication() {
  const [uName, setUName] = useState("");
  const [uPass, setUPass] = useState("");
  const [validationError, setValidationError] = useState({});
  const [show, setShow] = useState(0);

  const handleSubmit = async (e) => {
    const formData = new FormData();
    formData.append("uName", uName);
    formData.append("uPass", uPass);

    await axios.post(APIRoute.login, formData).then(({ data }) => {
      if (data.status === 200) {
        setShow(0);
        sessionStorage.setItem("session", 1);
        sessionStorage.setItem("typeLogin", data.typeLogin);
        sessionStorage.setItem("id", data.id);
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("sessionHidden", 1);
        window.location.reload();
      } else if (data.status === 401) {
        setShow(0);
        setValidationError("Correo o contraseña invalidos");
      } else if (data.status === 400) {
        setShow(0);
        setValidationError("Debe ingresar todo los campos");
      } else if (data.status === 500) {
        setShow(0);
        setValidationError("No existe conexión al servicio");
      }
    });
  };

  return (
    <>
      {sessionStorage.getItem("session") === 0 ||
      sessionStorage.getItem("session") === null ? (
        <div className="imgfondo">
          <div className="containerlogin">
            <div className="formcontainer2">
              <div className="card" style={{ background: "#F0F0F0" }}>
                <div
                  className="text-center align-items-center"
                  style={{ margin: "20px" }}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      bottom: "10px",
                      right: "10px",
                      width: "100px",
                      margin: 10,
                    }}
                  />
                  <br />
                  <h4>Bienvenido a la Intranet de Tasación por Fotografías</h4>
                </div>
                <div className="text-center align-items-center">
                  <hr />
                  <h5>Ingrese sus datos</h5>
                  <div className="form-wrapper">
                    {Object.keys(validationError).length > 0 && (
                      <div className="row">
                        <div className="col-12">
                          <div className="alert alert-danger">
                            <li>{validationError}</li>
                          </div>
                        </div>
                      </div>
                    )}
                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="userName" className="ml-2 mr-2">
                        <Form.Label>Correo Electronico</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Ingrese su nombre de usuario"
                          value={uName}
                          className="form-control input-sm chat-input"
                          onChange={(event) => {
                            setUName(event.target.value);
                          }}
                          style={{ opacity: "1" }}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="userPassword"
                        className="ml-2 mr-2"
                      >
                        <br />
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control
                          required
                          type="password"
                          autoComplete="on"
                          placeholder="Ingrese su contraseña"
                          value={uPass}
                          className="form-control input-sm chat-input"
                          onChange={(event) => {
                            setUPass(event.target.value);
                          }}
                          style={{ opacity: "1" }}
                        />
                      </Form.Group>
                      <div className="wrapper">
                        <Button
                          onClick={() => [setShow(1), handleSubmit()]}
                          disabled={show}
                          variant="primary"
                          className="group-btn"
                          size="lg"
                          block="block"
                          type="submit"
                          style={{ opacity: "1" }}
                        >
                          {show ? (
                            <div>
                              <Spinner
                                size="md"
                                animation="border"
                                role="status"
                                variant="warning"
                              />
                            </div>
                          ) : (
                            <>Ingresar</>
                          )}
                        </Button>
                      </div>
                      <div className="footer text-center">
                        <p>
                          © 2022 Valuaciones de Chile. Todos los Derechos
                          Reservados
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {sessionStorage.getItem("typeLogin") === "coordinator" ? (
            <CoordinationMenu />
          ) : sessionStorage.getItem("typeLogin") === "supervisor" ? (
            <SupervisorMenu />
          ) : sessionStorage.getItem("typeLogin") === "tasador" ? (
            <AppraiserMenu/>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
