import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import APIRoute from "../routersAPI/routes.json";
import { Alert, Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import "../../App.css";

export default function CreateAppraisal() {
  const [idAppraisal] = useState(sessionStorage.getItem("idAppraisal"));
  const [type_of_assets, setTypeOfAsset] = useState("");
  const [typeOfAssetList, setTypeOfAssetList] = useState([]);
  const [checkListTypeOfAssetsList, setCheckListTypeOfAssetsList] = useState(
    []
  );
  const [errorMessage, setErrorMessage] = useState([]);
  const [rol, setRol] = useState([]);
  const [bathroom, setBathroom] = useState([]);
  const [bedroom, setBedroom] = useState([]);
  const [terrain_area, setTerrainArea] = useState([]);
  const [construction_area, setConstructionArea] = useState([]);
  const [clientDescription, setClientDescription] = useState([]);
  const [address, setAddress] = useState("");
  const [id_commune, setCommune] = useState("");
  const [id_region, setRegion] = useState("");
  const [communeList, setCommuneList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [show, setShow] = useState(0);
  const [temp, setTemp] = useState([
    [1, "0"],
    [2, "0"],
    [4, "0"],
    [5, "0"],
  ]);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    fetchRegionList();
    fetchAppraisalData();
    fetchTypeOfAssetList();
  }, []);

  const fetchRegionList = async () => {
    axios.get(APIRoute.region_client).then(({ data }) => {
      setRegionList(data);
    });
  };

  const fetchAppraisalData = async () => {
    let dataR = "";
    let dataT = "";
    axios
      .get(APIRoute.appraisal + idAppraisal)
      .then(({ data }) => {
        setAddress(data["address"]);
        setRegion(data["id_region"]);
        dataR = data["id_region"];
        setCommune(data["id_commune"]);
        setBathroom(data["bathrooms"]);
        setBedroom(data["bedrooms"]);
        setTypeOfAsset(data["id_type_of_assets"]);
        dataT = data["id_type_of_assets"];
        setRol(data["rol"]);
      })
      .finally(() => {
        axios.get(APIRoute.commune + dataR).then(({ data }) => {
          setCommuneList(data);
        });
        axios.get(APIRoute.complement + dataT).then(({ data }) => {
          setCheckListTypeOfAssetsList(data);
        });
      });
  };

  const handleCommune = async (id_commune) => {
    const formData = new FormData();
    formData.append("id", id_commune);
    await axios
      .post(APIRoute.commune_client, formData)
      .then(({ data }) => {
        setCommuneList(data);
      })
      .catch(({ response }) => {
        if (response.status === 500) {
          setErrorMessage(response.data.errors);
        }
      });
  };

  const fetchTypeOfAssetList = async () => {
    axios.get(APIRoute.asset_client).then(({ data }) => {
      setTypeOfAssetList(data);
      setShow(1);
    });
  };

  const fillCompArray = async (data) => {
    let tempArray = [];
    let models = [];
    data.map(
      (item, index) => (
        (models = [item["id_desc_comp"], "0"]), tempArray.push(models)
      )
    );
    setTemp(tempArray);
  };

  const onchangeInput = (val, index, item_id) => {
    temp[index] = [item_id, val];
  };

  const handleAsset = async (e) => {
    setTypeOfAsset(e);
    axios
      .get(APIRoute.complement + e)
      .then(({ data }) => {
        fillCompArray(data);
        setCheckListTypeOfAssetsList(data);
      })
      .catch(({ response }) => {
        if (response.status === 500) {
          setErrorMessage(response.data.errors);
        }
      });
  };


  const createAppraisal = async (temp) => {
    const formData = new FormData();
    formData.append("id", idAppraisal);
    formData.append("idSupervisor", 1);
    formData.append("idCommune", id_commune);
    formData.append("address", address);
    formData.append("idTypeOfAsset", type_of_assets);
    sessionStorage.setItem("idTypeAsset", type_of_assets);
    formData.append("rol", rol);
    formData.append("terrainArea", terrain_area);
    formData.append("constructionArea", construction_area);
    formData.append("bedrooms", bedroom);
    formData.append("bathrooms", bathroom);
    formData.append("clientDescription", clientDescription);
    formData.append("reportStatus", 0);

    await axios
      .post(APIRoute.edit_appraisal + idAppraisal, formData)
      .then(({ data }) => {
        Swal.fire({
          text: "Datos confirmados con éxito",
          icon: "success",
        });
        createComplementary(temp);
      })
      .catch(({ response }) => {
        if(bathroom==0 || null){
          setBathroom("error");
        };
        if(bedroom==0 || null){
          setBedroom("error");
        };
        if(terrain_area==0 || null){
          setTerrainArea("error");
        };
        if(construction_area==0 || null){
          setConstructionArea("error");
        };
        sessionStorage.removeItem("statusConfirm");
        if (response.status) {
          Swal.fire({
            text: "Revise que todos los campos esten completados correctamente",
            icon: "error",
          });
          setErrorMessage(response.data.errors);
        }
      })
  };

  const createComplementary = async (temp) => {
    for (let i = 0; i < temp.length; i++) {
      let idDescComp = "";
      let quantityComplementarySender = "";
      if (temp[i] === null || temp[i] === "") {
        Swal.fire({
          text: "Error en complementarios",
          icon: "error",
        });
      } else {
        idDescComp = temp[i][0];
        quantityComplementarySender = temp[i][1];
        const formData = new FormData();
        formData.append("idAppraisal", idAppraisal);
        formData.append("idDescComp", idDescComp);
        formData.append("quantityComplementary", quantityComplementarySender);

        await axios
          .post(APIRoute.compcontroller_post, formData)
          .then(({ data }) => {})
          .catch(({ response }) => {
            if (response.status) {
              setErrorMessage(response.data.errors);
              Swal.fire({
                text: "error complementos",
                icon: "error",
              });
            }
          });
      }
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-sm-12 col-md-10">
          <div className="progress my-4" style={{ height: "30px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "100%" }}
              aria-label="Paso 1"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 1
            </div>
            <div
              className="progress-bar-grey"
              role="progressbar"
              style={{ width: "100%", textAlign: "center" }}
              aria-label="Paso 2"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 2
            </div>
            <div
              className="progress-bar-grey"
              role="progressbar"
              style={{ width: "100%", textAlign: "center" }}
              aria-label="Paso 3"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 3
            </div>
          </div>
          <div className="card">
            <div className="card-body bg-light ">
              <h4 className="card-title" style={{ textAlign: "center" }}>
                Detalles del bien
              </h4>
              <hr />
              <div className="form-wrapper">
                {show ? (
                  <Form onSubmit={createAppraisal}>
                    <Row className="my-3">
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="address"
                        >
                          <Form.Label column sm="2">
                            Dirección
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              size="lg"
                              placeholder="Ingrese dirección"
                              value={address}
                              onChange={(event) => {
                                setAddress(event.target.value);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="region"
                        >
                          <Form.Label column sm="2">
                            Región
                          </Form.Label>
                          <Col sm="10">
                            <Form.Select
                              aria-label="Default select example"
                              value={id_region}
                              onChange={(event) => {
                                setRegion(event.target.value);
                                handleCommune(event.target.value);
                              }}
                            >
                              <option value="" defaultValue disabled="active">
                                Seleccione Región
                              </option>
                              {regionList.map((item, index) => (
                                <option key={index} value={item.id_region}>
                                  {item.name_region}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="commune"
                          name="commune"
                        >
                          <Form.Label column sm="2">
                            Comuna
                          </Form.Label>
                          <Col sm="10">
                            <Form.Select
                              aria-label="Default select example"
                              value={id_commune}
                              onChange={(event) => {
                                setCommune(event.target.value);
                              }}
                            >
                              <option value="" defaultValue disabled="disabled">
                                Seleccione tipo de comuna
                              </option>
                              {communeList.map((item, index) => (
                                <option key={index} value={item.id_commune}>
                                  {item.name_commune}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="rol">
                          <Form.Label column sm="2">
                            Rol
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              size="lg"
                              placeholder="Ingrese rol"
                              required
                              value={rol}
                              onChange={(event) => {
                                setRol(event.target.value);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="type_of_assets"
                        >
                          <Form.Label column sm="2">
                            Tipo de bien
                          </Form.Label>
                          <Col sm="10">
                            <Form.Select
                              aria-label="Default select example"
                              value={type_of_assets}
                              onChange={(event) => {
                                handleAsset(event.target.value);
                              }}
                            >
                              <option value="" defaultValue disabled="disabled">
                                Seleccione tipo de bien
                              </option>
                              {typeOfAssetList.map((item, index) => (
                                <option
                                  key={index}
                                  value={item.id_type_of_assets}
                                >
                                  {item.description}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="3">
                            Complementos
                          </Form.Label>
                          <Col sm="9">
                            {checkListTypeOfAssetsList.map((item, index) => (
                              <Col
                                className="row alig-content-center"
                                sm="12"
                                key={index}
                              >
                                <Form.Label
                                  className="col-12"
                                  value={item.id_desc_comp}
                                  type="label"
                                  aria-label="nameComplement"
                                  hidden
                                />
                                <p className="col-9 row alig-content-center">
                                  {item.name_comp}
                                </p>
                                <Form.Control
                                  style={item.quantityComplementary == 0 || "" ? ({border: "1px solid red"}) : ({})}
                                  className="col-3"
                                  placeholder="0"
                                  type="number"
                                  min="0"
                                  required
                                  aria-label="numberInput"
                                  value={item.quantityComplementary}
                                  onChange={(e) => {
                                    onchangeInput(
                                      e.target.value,
                                      index,
                                      item.id_desc_comp
                                    );
                                  }}
                                />
                                <br /> <br />
                              </Col>
                            ))}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                        >
                          <Form.Label column sm="2">
                            N° Baños
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              style={bathroom == "error" ? ({border: "1px solid red"}) : ({})}
                              type="number"
                              size="lg"
                              placeholder="0"
                              required
                              onChange={(event) => {
                                setBathroom(event.target.value);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
  
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="bedroom"
                        >
                          <Form.Label column sm="2">
                            N° Habitaciones
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              style={bedroom == "error" ? ({border: "1px solid red"}) : ({})}
                              className="error"
                              type="number"
                              size="lg"
                              placeholder="0"
                              required
                              onChange={(event) => {
                                setBedroom(event.target.value);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="terrain_area"
                        >
                          <Form.Label column sm="2">
                            {type_of_assets == 1 ? (
                              <>Superficie de terreno total</>
                            ) : type_of_assets == 2 ? (
                              <>Superficie departamento total</>
                            ) : (
                              <>Seleccione tipo de bien</>
                            )}
                          </Form.Label>
                          <Col sm="10">
                            {type_of_assets == 1 ? (
                              <Form.Control
                                style={terrain_area == "error" ? ({border: "1px solid red"}) : ({})}
                                type="number"
                                size="lg"
                                placeholder="Ingrese superficie terreno total"
                                required
                                value={terrain_area}
                                onChange={(event) => {
                                  setTerrainArea(event.target.value);
                                }}
                              />
                            ) : type_of_assets == 2 ? (
                              <Form.Control
                                style={terrain_area == "error" ? ({border: "1px solid red"}) : ({})}
                                type="number"
                                size="lg"
                                placeholder="Ingrese Superficie departamento total"
                                required
                                value={terrain_area}
                                onChange={(event) => {
                                  setTerrainArea(event.target.value);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="construction_area"
                        >
                          <Form.Label column sm="2">
                            {type_of_assets == 1 ? (
                              <>Área construida</>
                            ) : type_of_assets == 2 ? (
                              <>Superficie terraza</>
                            ) : (
                              <>Seleccione tipo de bien</>
                            )}
                          </Form.Label>
                          <Col sm="10">
                            {type_of_assets == 1 ? (
                              <Form.Control
                                style={construction_area == "error" ? ({border: "1px solid red"}) : ({})}
                                type="number"
                                size="lg"
                                placeholder="Ingrese área en construcción total"
                                required
                                value={construction_area}
                                onChange={(event) => {
                                  setConstructionArea(event.target.value);
                                }}
                              />
                            ) : type_of_assets == 2 ? (
                              <Form.Control
                                style={construction_area == "error" ? ({border: "1px solid red"}) : ({})}
                                type="number"
                                size="lg"
                                placeholder="Ingrese Superficie terraza"
                                required
                                value={construction_area}
                                onChange={(event) => {
                                  setConstructionArea(event.target.value);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="clientDescription"
                        >
                          <Form.Label column sm="2">
                            Descripción del bien (detalles y/o adicionales)
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              as="textarea"
                              type="text"
                              size="lg"
                              placeholder="Ej. Detalles propiedad (cañeria mala), tamaño bodega (20m2), tipo de ventanas (termopanel), estado del piso o alfombrado"
                              
                              value={clientDescription}
                              onChange={(event) => {
                                setClientDescription(event.target.value);
                              }}
                            />
                          </Col>
                          <Modal show={showModal} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>
                                Ejemplos Descripción del bien
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <h6>
                                En el cuadro Descripción del bien debe describir
                                el máximo de detalles que posea su bien
                              </h6>
                              <p>Ejemplos: </p>
                              {type_of_assets == 1 ? (
                                <>
                                <p align="Justify">Vivienda de ± 30 años de antigüedad, pareada a similar invertida, desarrollada en 2 pisos más mansarda, emplazada en la vereda poniente de calle Arquitecto Enrique Aguirre, a pasos de la intersección de Av. Vitacura con Tabancura, ejes que le otorgan importante conectividad urbana y servicios asociados. Presenta completo programa habitacional, con superficie de 54,6 m² en 1er piso, 54,6 m² en 2do piso y 25,86 m² en mansarda. Además, presenta ampliación de cocina en 1er piso con 10,78 m² y 14,30 m² en mansarda (± Año 2005). Construcciones sobre terreno levemente irregular con frente de 9,25 mts y una superficie de 244,13 m². Cabe señalar que en DOM de Vitacura no se encontraron antecedentes de ampliaciones, siendo factibles de regularizar de acuerdo a normativa del PRC.</p>

                                <p align="Justify"><b>También puede especificar tipo de terminaciones, orientación, terrazas con cerramientos, logia, tipo de calefacción. Última remodelación o renovación de terminaciones, muebles fijos (Cocina, baño o closet), artefactos, ventanas (termopanel o vidrio simple).</b></p>
                              </>
                            ) : type_of_assets == 2 ? (
                              <>
                                <p align="Justify">Conjunto de ± 2 años de antigüedad, denominado Altos de Santo Domingo, compuesto por dos torres de 27 pisos y 4 subterráneos, asistidos por 3 ascensores cada una, con departamentos de 1, 2 y 3 dormitorios. Consta de hall de acceso doble altura, conserjería, grupo electrógeno de emergencia, dos salas multiuso, gimnasio equipado, sala de juegos, lavandería, baños para el personal de servicio y circuito cerrado de TV.</p>
                                <p align="Justify">El departamento analizado se ubica en piso 24, orientación sur-poniente con vistas despejadas, completo programa habitacional de 3 dormitorios (1 en suite), estar comedor unidos, cocina, dormitorio de servicio, loggia y terraza. Posee una superficie útil de 101,90 m² y 2,70 m² de loggia y 9,10 m² de terraza. Cuenta con estacionamiento Nº 141 asociado con bodega Nº 146 en primer subterráneo y estacionamiento Nº 132 también en primer subterráneo. Cuenta con sello verde.</p>
                                <p align="Justify"><b>También puede especificar tipo de terminaciones, orientación, terrazas con cerramientos, logia, tipo de calefacción. Última remodelación o renovación de terminaciones, muebles fijos (Cocina, baño o closet), artefactos, ventanas (termopanel o vidrio simple).</b></p>
                              </>
                            ) : (
                              <>Seleccione tipo de bien</>
                            )}
                              
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="primary" onClick={handleClose}>
                                OK
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </Form.Group>
                        <Button onClick={handleShow} variant="info">
                          Ejemplos descripción del bien
                        </Button>
                      </Col>
                    </Row>
                    <Alert variant="warning">
                      Asegurese de rellenar <b>TODOS</b> los campos y revisar
                      bien los datos antes de continuar (<b>No</b> se puede
                      volver atras, asegurese de confirmar sus datos)
                    </Alert>
                    <Button
                      variant="success"
                      style={{ textAlign: "center" }}
                      onClick={() => {
                        createAppraisal(temp);
                        sessionStorage.setItem("statusConfirm", true);
                      }}
                    >
                      Confirmar datos
                    </Button>
                  </Form>
                ) : (
                  <div className="">
                    <Spinner animation="border" role="status"></Spinner>
                    <span className="">Cargando datos...</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
