import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import APIRoute from "../routersAPI/routes.json";
import Button from "react-bootstrap/Button";
import { IoTrash } from "react-icons/io5";
import { FcOk } from "react-icons/fc";
import { Alert } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export default function CreateAppraisal3() {
  const [idAppraisal] = useState(sessionStorage.getItem("idAppraisal"));
  const [nameFile, setNameFile] = useState("");
  const [idArchive, setIdArchive] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  let [checkDoc, setCheckDoc] = useState(false);
  let [checkDoc2, setCheckDoc2] = useState(false);
  let [checkDoc3, setCheckDoc3] = useState(false);
  let [checkDoc4, setCheckDoc4] = useState(false);
  let [show, setShow] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const createAppraisal3 = async () => {
    const formData = new FormData();
    formData.append("id_appraisal", idAppraisal);
    formData.append("name_file", nameFile);
    formData.append("id_archive_type", idArchive);
    if (idArchive === 1) {
      setCheckDoc(true);
    } else if (idArchive === 2) {
      setCheckDoc2(true);
    } else if (idArchive === 3) {
      setCheckDoc3(true);
    } else if (idArchive === 4) {
      setCheckDoc4(true);
    }
    await axios
      .post(APIRoute.doc_post, formData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: "Documento cargado con éxito",
        });
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
        } else {
          Swal.fire({
            text: errorMessage,
            icon: "error",
          });
          setShow(0);
        }
      })
      .finally(() => sessionStorage.removeItem("docUploading"));
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-sm-12 col-md-10">
          <div className="progress my-4" style={{ height: "30px" }}>
            <div
              className="progress-bar-grey"
              role="progressbar"
              style={{ width: "100%", textAlign: "center" }}
              aria-label="Paso 1"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 1
            </div>
            <div
              className="progress-bar-grey"
              role="progressbar"
              style={{ width: "100%", textAlign: "center" }}
              aria-label="Paso 2"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 2
            </div>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "100%" }}
              aria-label="Paso 3"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 3
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title" style={{ textAlign: "center" }}>
                Tipo de documentos
              </h4>
              <Row className="my-3">
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4
                        className="card-title"
                        style={{ textAlign: "center" }}
                      >
                        Escritura
                      </h4>
                      <hr />
                      <div className="form-wrapper">
                        <Form onSubmit={createAppraisal3}>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Documentos (Word, Excel, PDF o Foto)
                                (28MB max)
                              </Form.Label>
                              <Form.Control
                                disabled={checkDoc}
                                maxfilesize={28400000}
                                type="file"
                                name="nameFile"
                                onChange={(e) => {
                                  setNameFile(e.target.files[0]);
                                  setIdArchive(1);
                                }}
                                accept=".xls,.xlsx,.doc,.docx,.pdf"
                              />
                            </Form.Group>
                            <Row sm="12">
                              <Col className="col-3">
                                <Button
                                  variant="danger"
                                  onClick={() => setCheckDoc(false)}
                                >
                                  <IoTrash />
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  disabled={checkDoc}
                                  onClick={() => {
                                    createAppraisal3();
                                    sessionStorage.setItem(
                                      "docUploading",
                                      true
                                    );
                                  }}
                                >
                                  Cargar
                                </Button>
                              </Col>
                              <Col>
                                <p
                                  style={{ fontSize: "25px" }}
                                  hidden={!checkDoc}
                                >
                                  <FcOk />
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4
                        className="card-title"
                        style={{ textAlign: "center" }}
                      >
                        Certificados Sii
                      </h4>
                      <hr />
                      <div className="form-wrapper">
                        <Form onSubmit={createAppraisal3}>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Documentos (Word, Excel, PDF o Foto)
                                (28MB max)
                              </Form.Label>
                              <Form.Control
                                disabled={checkDoc2}
                                maxfilesize={28400000}
                                type="file"
                                name="nameFile"
                                onChange={(e) => {
                                  setNameFile(e.target.files[0]);
                                  setIdArchive(2);
                                }}
                                accept=".xls,.xlsx,.doc,.docx,.pdf"
                              />
                            </Form.Group>
                            <Row sm="12">
                              <Col className="col-3">
                            <Button
                              variant="danger"
                              onClick={() => setCheckDoc2(false)}
                            >
                              <IoTrash />
                            </Button>
                            </Col>
                            <Col>
                            <Button
                              disabled={checkDoc2}
                              onClick={() => {
                                createAppraisal3();
                                sessionStorage.setItem("docUploading", true);
                              }}
                            >
                              Cargar
                            </Button>
                            </Col>
                            <Col>
                                <p
                                  style={{ fontSize: "25px" }}
                                  hidden={!checkDoc2}
                                >
                                  <FcOk />
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4
                        className="card-title"
                        style={{ textAlign: "center" }}
                      >
                        Planos
                      </h4>
                      <hr />
                      <div className="form-wrapper">
                        <Form onSubmit={createAppraisal3}>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Documentos (Word, Excel, PDF o Foto)
                                (28MB max)
                              </Form.Label>
                              <Form.Control
                                disabled={checkDoc3}
                                maxfilesize={28400000}
                                type="file"
                                name="nameFile"
                                onChange={(e) => {
                                  setNameFile(e.target.files[0]);
                                  setIdArchive(3);
                                }}
                                accept=".xls,.xlsx,.doc,.docx,.pdf"
                              />
                            </Form.Group>
                            <Row sm ="12">
                              <Col className="col-3">
                            <Button
                              variant="danger"
                              onClick={() => setCheckDoc3(false)}
                            >
                              <IoTrash />
                            </Button>
                            </Col>
                            <Col>
                            <Button
                              disabled={checkDoc3}
                              onClick={() => {
                                createAppraisal3();
                                sessionStorage.setItem("docUploading", true);
                              }}
                            >
                              Cargar
                            </Button>
                            </Col>
                            <Col>
                                <p
                                  style={{ fontSize: "25px" }}
                                  hidden={!checkDoc3}
                                >
                                  <FcOk />
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4
                        className="card-title"
                        style={{ textAlign: "center" }}
                      >
                        Otros
                      </h4>
                      <hr />
                      <div className="form-wrapper">
                        <Form onSubmit={createAppraisal3}>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Documentos (Word, Excel, PDF o Foto)
                                (28MB max)
                              </Form.Label>
                              <Form.Control
                                disabled={checkDoc4}
                                maxfilesize={28400000}
                                type="file"
                                name="namefile"
                                onChange={(e) => {
                                  setNameFile(e.target.files[0]);
                                  setIdArchive(4);
                                }}
                                multiple
                                accept=".xls,.xlsx,.doc,.docx,.pdf"
                              />
                            </Form.Group>
                            <Row sm ="12">
                              <Col className="col-3">
                            <Button
                              variant="danger"
                              onClick={() => setCheckDoc4(false)}
                            >
                              <IoTrash />
                            </Button>
                            </Col>
                            <Col>
                            <Button
                              disabled={checkDoc4}
                              onClick={() => {
                                createAppraisal3();
                                sessionStorage.setItem("docUploading", true);
                              }}
                            >
                              Cargar
                            </Button>
                            </Col>
                            <Col>
                                <p
                                  style={{ fontSize: "25px" }}
                                  hidden={!checkDoc4}
                                >
                                  <FcOk />
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
            <div className="container row text-align:center">
              <p style={{ fontSize: "18px", textAlign: "center" }}>
                <Alert variant="warning">
                  Asegurese de cargar <b>TODOS</b> los documentos antes de
                  finalizar <b>No</b> se puede volver atras, asegurese de
                  confirmar sus datos
                </Alert>
                Al usar este servicio acepta los
                <Button onClick={handleShow} variant="link">
                  Términos y condiciones{" "}
                </Button>
                <Modal show={showModal} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Términos y condiciones</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      1. La tasación se realizará utilizando únicamente la
                      información y fotografías proporcionadas por el
                      solicitante. Valuaciones de Chile no se hace responsable
                      de la precisión de la información proporcionada ni de la
                      calidad de las fotografías.
                    </p>
                    <p>
                      2. La tasación es solo una estimación del valor del bien
                      en cuestión y no constituye una valoración oficial ni una
                      garantía de precio de venta.
                    </p>
                    <p>
                      3. La tasación no incluye una inspección física del bien,
                      por lo que no se garantiza su precisión en caso de que
                      existan defectos o daños no visibles en las fotografías
                      proporcionadas.
                    </p>
                    <p>
                      4. El solicitante acepta que la tasación se realizará de
                      buena fe y que Valuaciones de Chile no se hace responsable
                      de posibles pérdidas o daños resultantes de la tasación.
                    </p>
                    <p>
                      5. El solicitante debe proporcionar su nombre y correo
                      electrónico para poder recibir la tasación. Valuaciones de
                      Chile se reserva el derecho de utilizar esta información
                      para fines comerciales, como enviar ofertas o promociones.
                    </p>
                    <p>
                      6. El solicitante acepta que utilizara el servicio bajo su
                      propio riesgo.
                    </p>
                    <p>
                      7. Valuaciones de Chile se reserva el derecho de modificar
                      o interrumpir el producto o servicio en cualquier momento
                      y sin previo aviso.
                    </p>
                    <p>
                      8. El solicitante acepta que no tiene derecho a un
                      reembolso una vez que haya utilizado el producto o
                      servicio.
                    </p>
                    <p>
                      9. El solicitante acepta los términos y condiciones al
                      enviar la solicitud de tasación.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                      OK
                    </Button>
                  </Modal.Footer>
                </Modal>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
