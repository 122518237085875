import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import Swal from "sweetalert2";
import { MDBDataTableV5 } from "mdbreact";
import { FiEdit2, FiCheckCircle } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { Spinner } from "react-bootstrap";

export default function AppraisalList() {
  const [appraisalList, setAppraisalList] = useState([]);
  const [reportStatus, setReportStatus] = useState(0);
  const [show, setShow] = useState(0);
  var [idSupervisor] = useState(sessionStorage.getItem("id"));

  const headCells = [
    {
      label: "Dirección",
      field: "address",
    },
    {
      label: "Rol",
      field: "rol",
    },
    {
      label: "Superficie terreno",
      field: "terrainArea",
    },
    {
      label: "Área en construcción",
      field: "constructionArea",
    },
    {
      label: "Descipción",
      field: "clientDesc",
    },
    {
      label: "Entrega Cliente",
      field: "status",
    },
    {
      label: "Estado Supervisión",
      field: "reportstatus",
    },
    {
      label: "Acción",
      field: "actionBtn",
    },
  ];
  const [datatable, setDatatable] = useState({
    columns: headCells,
    rows: [],
  });
  useEffect(() => {
    fetchAppraisal();
  }, []);

  const fetchAppraisal = async () => {
    setShow(1);
    axios.get(APIRoute.appraisal).then(({ data }) => {
      setAppraisalList(data);
      setDatatable({
        columns: headCells,
        rows: data.map((appraisal) => {
          let status = "";
          if (appraisal.status === 1) {
            status = "REALIZADA";
          } else {
            status = "PENDIENTE";
          }
          let reportstatus = "";
          if (appraisal.reportstatus === 1) {
            reportstatus = "VISADA";
          } else {
            reportstatus = "SIN VISAR";
          }
          if (reportstatus === "SIN VISAR") {
            return {
              id: appraisal.id_appraisal,
              address: appraisal.address,
              rol: appraisal.rol,
              terrainArea: appraisal.terrain_area,
              constructionArea: appraisal.construction_area,
              clientDesc: appraisal.client_description,
              status: status,
              reportstatus: reportstatus,
              actionBtn: (
                <>
                  <div
                    className="d-grid col-md-6 col-lg-9 col-sm-3"
                    style={{ fontSize: "12px" }}
                  >
                    <Button
                      title="Visar"
                      className="btn btn-primary"
                      onClick={() => visaAppraisal(appraisal.id_appraisal)}
                    >
                      <FiCheckCircle style={{ margin: 0, fontSize: "20px" }} />
                    </Button>

                    <Link to={APIRoute.editAppraisal + appraisal.id_appraisal}>
                      <Button title="Editar" className="btn btn-success">
                        <FiEdit2 style={{ margin: 0, fontSize: "20px" }} />
                      </Button>
                    </Link>
                    <Button
                      title="Eliminar"
                      className="btn btn-danger"
                      onClick={() => deleteAppraisal(appraisal.id_appraisal)}
                    >
                      <MdDeleteForever
                        style={{ margin: 0, fontSize: "20px" }}
                      />
                    </Button>
                  </div>
                </>
              ),
            };
          }
        }),
      });
    });
  };
  const updateAppraisal = async (id) => {
    const formData = new FormData();
    formData.append("id", id);
    await axios
      .post(APIRoute.appraisal + id, formData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
      })
      .catch(({ response }) => {
        if (response.status) {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  const visaAppraisal = async (id) => {
    const { value: file } = await Swal.fire({
      title: "Ingrese PDF final para visar",
      input: "file",
      inputAttributes: {
        accept: "PDF",
      },
    });

    if (file) {
      setShow(0);
      const formData = new FormData();
      formData.append("id_appraisal", id);
      formData.append("name", file);
      formData.append("id_supervisor", idSupervisor);
      await axios.post(APIRoute.visapdf, formData).then(() => {
        changeStateVisa(id);
      });
    } else {
      setReportStatus(0);
      Swal.fire("Cambios no guardados", "", "warning");
    }
  };

  const changeStateVisa = async (id) => {
    setReportStatus(1);
    
    const formData = new FormData();
    formData.append("id_appraisal", id);
    formData.append("id_supervisor", idSupervisor);
    await axios
      .post(APIRoute.sendpdf, formData)
      .then(() => {
        setShow(0);
        fetchAppraisal();
        updateAppraisal(id);
      })
      .catch(() => {
        Swal.fire({
          text: "no se pudo enviar el informe a coordinación",
          icon: "error",
        });
      });
  };

  const deleteAppraisal = async (id) => {
    const isConfirm = await Swal.fire({
      title: "¿Estas seguro?",
      text: "No podras revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }
    await axios
      .delete(APIRoute.appraisal + id, { data: { id: idSupervisor } })
      .then(() => {
        Swal.fire({
          icon: "success",
          text: "Se eliminó la tasación con éxito",
        });
        fetchAppraisal();
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };
  return (
    <div className="container">
    <h3 style={{display: 'flex', justifyContent:'center'}}>Tasaciones por visar</h3>
      <div className="row">
        <div className="col-12">
          {show ? (
            <MDBDataTableV5
              hover
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              searchTop
              searchBottom={false}
              searchLabel="Busqueda general"
              exportToCSV
              responsive
              data={datatable}
            />
          ) : (
            <div>
              <Spinner animation="border" role="status"></Spinner>
              <span className="">Cargando datos...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
