import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import CreateAppraisal from "./create.component";
import CreateAppraisal2 from "./create2.component";
import CreateAppraisal3 from "./create3.component";
import "../../App.css";
import { FiLogOut } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";

export function FormUser() {
  const [page, setPage] = useState(0);
  var [confirmValue, setConfirm] = useState();
  var [confirmPhoto, setConfirmPhoto] = useState();
  var [confirmDoc, setConfirmDoc] = useState();
  const [idAppraisal] = useState(sessionStorage.getItem("idAppraisal"));

  useEffect(() => {
    const interval = setInterval(() => {
      setConfirm(sessionStorage.getItem("statusConfirm"));
      setConfirmPhoto(sessionStorage.getItem("Fachada"));
      setConfirmDoc(sessionStorage.getItem("docUploading"));
    }, 2000);
  }, []);

  const finalizeAppraisal = () => {
    let timerInterval;
    Swal.fire({
      title: "Tasación Finalizada",
      html: "Gracias por confiar en nosotros, le contactaremos a la brevedad.",
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        window.location = "/";
        sessionStorage.removeItem("session");
        sessionStorage.removeItem("typeLogin");
        sessionStorage.removeItem("idUser");
        sessionStorage.removeItem("idAppraisal");
        sessionStorage.removeItem("Fachada");
        sessionStorage.removeItem("sessionHidden");
        window.location.href = '/';
      }
    });
  };

  const usedCode = () => {
    const formData = new FormData();
    formData.append("id_appraisal", idAppraisal);
    axios.post(APIRoute.usecode, formData);
    formData.append("id_appraisal", idAppraisal);
    axios.post(APIRoute.finish, formData);
  };

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <>
          <CreateAppraisal />
        </>
      );
    } else if (page === 1) {
      return (
        <>
          <CreateAppraisal2 />
          {sessionStorage.removeItem("statusConfirm")}
        </>
      );
    } else if (page === 2) {
      return (
        <>
          <CreateAppraisal3 />
        </>
      );
    }
  };
  return (
    <div className="container">
      {PageDisplay()}
      <div className="row justify-content-center align-items-center">
        <div className=" col">
          <div className="header my-2">
            <div className="body">
              <div className="form"></div>
              {page !== 3 ? (
                <>
                  {page !== 2 ? (
                    <Button
                      className="btn btn-primary "
                      disabled={confirmValue || confirmPhoto ? false : true}
                      onClick={() => {
                        setPage((currPage) => currPage + 1);
                      }}
                    >
                      Siguiente
                    </Button>
                  ) : (
                    <Button
                      disabled={confirmDoc ? true : false}
                      onClick={() => {
                        usedCode();
                        finalizeAppraisal();
                      }}
                      >
                      <FiLogOut size={20} className="float-right text-white" />
                      Finalizar tasación
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormUser;
