import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { MDBDataTableV5 } from "mdbreact";
import { FiEdit2, FiUserCheck } from "react-icons/fi";
import { Spinner } from "react-bootstrap";
import CreateButton from "./create.button";
import Modal from "react-bootstrap/Modal";

export default function ClientList() {
  const [appraiserList, setAppraiserList] = useState([]);
  const [idAppraiser, setIdAppraiser] = useState("");
  const [clientlist, setClientlists] = useState([]);
  const [show, setShow] = useState(0);
  const [showModal, setShowModal] = useState(false);
  let [idAppraisal, setIdAppraisal] = useState("");
  let [idCoordinator] = useState(sessionStorage.getItem("id"));
  var [appraiser, setAppraiser] = useState([]);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const headCells = [
    {
      label: "Rut",
      field: "rut",
    },
    {
      label: "Nombre",
      field: "name",
    },
    {
      label: "Telefono",
      field: "phone",
    },
    {
      label: "Correo",
      field: "emailClient",
    },
    {
      label: "Estado / Fecha y hora término",
      field: "status",
    },
    {
      label: "Tasador",
      field: "appraiser",
    },
    {
      label: "Acción",
      field: "actionBtn",
    },
  ];
  const [datatable, setDatatable] = useState({
    columns: headCells,
    rows: [],
  });
  useEffect(() => {
    fetchClient();
    fetchAppraiser();
  }, []);

  const fetchAppraiser = async () => {
    await axios.get(APIRoute.appraiser).then(({ data }) => {
      setAppraiserList(data);
    });
  };

  const fetchClient = async () => {
    axios.get(APIRoute.clients).then(({ data }) => {
      setClientlists(data);
      setShow(1);
      setDatatable({
        columns: headCells,
        rows: data.map((client) => {
          let status = "";
          let date = "";
          let time = new Date(client.updated_at);
          let formatedTime = "";
          let appraiser = "";
          if (client.status === 1) {
            status = "REALIZADA";
            date = client.updated_at.substring(0, 10);
            time.setHours(time.getHours());
            formatedTime = time.getHours() + ':' + time.getMinutes();
          } else {
            status = "PENDIENTE";
          }
          if (client.id_status === null) {
            appraiser = "TASADOR NO ASIGNADO";
          } else {
            appraiser = client.name_appraiser;
          }
          return {
            id: client.id_client,
            rut: client.rut_client,
            name: client.name_client,
            phone: client.phone_client,
            emailClient: client.email_client,
            status: status + " / " + date + " a las " + formatedTime,
            appraiser: appraiser,
            actionBtn: (
              <>
                <div
                  className="d-grid col-md-3 col-lg-3 col-sm-7"
                  style={{ fontSize: "12px" }}
                >
                  <Link to={APIRoute.editClient + client.id_client}>
                    <Button title="Editar" className="btn btn-success">
                      <FiEdit2 style={{ margin: 0, fontSize: "20px" }} />
                    </Button>
                  </Link>
                  <Button
                    title="Asignar"
                    className="btn btn-primary"
                    onClick={() => [
                      setIdAppraisal(client.id_appraisal),
                      handleShow(),
                    ]}
                  >
                    <FiUserCheck style={{ margin: 0, fontSize: "20px" }} />
                  </Button>
                </div>
              </>
            ),
          };
        }),
      });
    });
  };
  const assignAppraiser = async () => {
    const formData = new FormData();
    formData.append("idAppraisal", idAppraisal);
    formData.append("idAppraiser", idAppraiser);
    formData.append("idCoordinator", idCoordinator);
    formData.append("status", 0);
    await axios
      .post(APIRoute.assign, formData)
      .then(() => {
        handleClose();
        fetchClient();
        fetchAppraiser();
      })
      .catch(({ response }) => {
        if (response.status === 402) {
          Swal.fire({
            text: "esta tasación ya posee tasador asignado",
            icon: "error",
          });
        } else {
          Swal.fire({
            text: "Error al asignar el tasador",
            icon: "error",
          });
        }
      });
  };
  return (
    <div className="container">
      <div className="row">
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Asignar tasador</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Select
              aria-label="Asignar tasador"
              value={idAppraiser}
              required
              onChange={(event) => {
                setIdAppraiser(event.target.value);
              }}
            >
              <option value="" defaultValue disabled="disabled">
                Seleccione tasador
              </option>
              {appraiserList.map((item, index) => (
                <option key={index} value={item.id_appraiser}>
                  {item.name_appraiser}
                </option>
              ))}
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => assignAppraiser()}>
              Asignar
            </Button>
          </Modal.Footer>
        </Modal>
        <CreateButton />
        <div className="col-12">
          {show ? (
            <MDBDataTableV5
              hover
              entriesOptions={[5, 15, 25]}
              entries={5}
              pagesAmount={4}
              searchTop
              searchBottom={false}
              searchLabel="Buscar"
              exportToCSV
              responsive
              data={datatable}
            />
          ) : (
            <div>
              <Spinner animation="border" role="status"></Spinner>
              <span className="sm-3">Cargando datos...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
