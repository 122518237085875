import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import { MDBDataTableV5 } from "mdbreact";
import { FaFileDownload } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
export default function VisatedAppraisal() {
  const [appraisalList, setAppraisalList] = useState([]);
  const [show, setShow] = useState(0);
  var [idSupervisor] = useState(sessionStorage.getItem("id"));

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const headCells = [
    {
      label: "Dirección",
      field: "address",
    },
    {
      label: "Rol",
      field: "rol",
    },
    {
      label: "Superficie terreno",
      field: "terrainArea",
    },
    {
      label: "Área en construcción",
      field: "constructionArea",
    },
    {
      label: "Descipción",
      field: "clientDesc",
    },
    {
      label: "Entrega Cliente",
      field: "status",
    },
    {
      label: "Estado Supervisión",
      field: "reportstatus",
    },
    {
      label: "Fecha Visado",
      field: "updatedAt",
    },
    {
      label: "Acción",
      field: "actionBtn",
    },
  ];
  const [datatable, setDatatable] = useState({
    columns: headCells,
    rows: [],
  });
  useEffect(() => {
    fetchAppraisal();
  }, [APIRoute.appraisal]);

  async function fetchAppraisal() {
    setShow(1);
    await axios.get(APIRoute.appraisal).then(({ data }) => {
      setAppraisalList(data);
      setDatatable({
        columns: headCells,
        rows: data.map((appraisal) => {
          let status = "";
          if (appraisal.status === 1) {
            status = "REALIZADA";
          } else {
            status = "PENDIENTE";
          }
          let reportstatus = "";
          if (appraisal.reportstatus === 1) {
            reportstatus = "VISADA";
          } else {
            reportstatus = "SIN VISAR";
          }
          if (reportstatus === "VISADA") {
            return {
              id: appraisal.id_appraisal,
              address: appraisal.address,
              rol: appraisal.rol,
              terrainArea: appraisal.terrain_area,
              constructionArea: appraisal.construction_area,
              clientDesc: appraisal.client_description,
              status: status,
              reportstatus: reportstatus,
              updatedAt:
                appraisal.updated_at.substring(0, 10) +
                " / " +
                appraisal.name_supervisor,
              actionBtn: (
                <>
                  <div
                    className="d-grid col-md-6 col-lg-9 col-sm-3"
                    style={{ fontSize: "12px" }}
                  >
                    <Button
                      title="Visualizar"
                      className="btn btn-primary"
                      onClick={() => visaFile(appraisal.id_appraisal)}
                    >
                      <FaFileDownload />
                    </Button>
                  </div>
                </>
              ),
            };
          }
        }),
      });
    });
  }

  const visaFile = async (id) => {
    await axios.get(APIRoute.doc + id).then(({ data }) => {
      for (let i = 0; i <= data.length; i++) {
        if (data[i].id_archive_type === 5) {
          openInNewTab(APIRoute.URL+"/"+data[i].name_file);
        }
      }
    });
  };

  return (
    <div className="container">
    <h3 style={{display: 'flex', justifyContent:'center'}}>Tasaciones visadas</h3>
      <div className="row">
        <div className="col-12">
          {show ? (
            <MDBDataTableV5
              hover
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              searchTop
              searchBottom={false}
              searchLabel="Busqueda general"
              exportToCSV
              responsive
              data={datatable}
            />
          ) : (
            <div>
              <Spinner animation="border" role="status"></Spinner>
              <span className="">Cargando datos...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
