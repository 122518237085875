import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Nav, Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import logo from "../../logo.svg";
import CreateClient from '../client/create.component';
import ClientList from '../client/list.component';
import EditClient from '../client/edit.component';

export default function CoordinationMenu() {
  return (
    <Router>
           <Navbar collapseOnSelect expand="sm" className="navegationBar" variant="dark" sticky="top">
                <Container>
                    <Navbar.Brand className="navbar-brand text-white" >
                        <Link to={"/"} className="navbar-brand text-white">
                            <img src={logo} alt="Logo" style={{marginLeft:'1rem', width: '5rem', height: '100%' }} />
                            <div style={{ fontSize: '15px', marginLeft:'0.5rem'}}>Valuaciones SPA</div>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-brand text-white" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Link to={"/client"} className="navbar-brand text-white">
                                Lista de clientes
                            </Link>
                        </Nav>
                        <Link to={"/"} className="navbar-brand text-white">
                        {sessionStorage.getItem('session') !== 0 || sessionStorage.getItem('session') != null ?
                                    <div className='logout text-white'
                                        onClick={(e) => {
                                            sessionStorage.removeItem("session");
                                            sessionStorage.removeItem('typeLogin');
                                            sessionStorage.removeItem('token');
                                            sessionStorage.removeItem('id');
                                            sessionStorage.setItem('sessionHidden', 0);
                                            window.location.href = '/';
                                        }}
                                    >   
                                <FiLogOut className='float-right text-white' />Cerrar sesión</div>
                        : <></>
                        }
                        </Link> 
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className="mt-5">
                <Row>
                    <Col md={12}>
                        <Routes>  
                            {/* ===========RUTAS COORDINADOR ==================== */}
                            <Route exact path="/" element={<ClientList />}/>
                            <Route exact path="client" element={<ClientList/>}/>
                            <Route exact path="/client/create" element={<CreateClient />} />
                            <Route exact path="/client/edit/:id" element={<EditClient />} />
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </Router>
    );
}