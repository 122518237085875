import React, { useState, Fragment } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import Swal from "sweetalert2";
import ImageUploading from "react-images-uploading";
import "../../App.css";
import { Alert, Modal, Carousel } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

export default function CreateAppraisal2() {
  let [status, setStatus] = useState(false);
  const [isCameraOpen2, setIsCameraOpen2] = useState("");
  const [isCameraOpen3, setIsCameraOpen3] = useState(false);
  const [isCameraOpen4, setIsCameraOpen4] = useState(false);
  const [isCameraOpen5, setIsCameraOpen5] = useState(false);
  const [isCameraOpen6, setIsCameraOpen6] = useState(false);
  const [idAppraisal] = useState(sessionStorage.getItem("idAppraisal"));
  const [idTypeAsset] = useState(sessionStorage.getItem("idTypeAsset"));
  const [latitude] = useState(0);
  const [longitude] = useState(0);
  const [images, setImages] = useState([]);
  const [imagesBathRoom, setImagesBathRoom] = useState([]);
  const [imagesKitchen, setImagesKitchen] = useState([]);
  const [imagesBedRoom, setImagesBedRoom] = useState([]);
  const [imagesLivingRoom, setImagesLivingRoom] = useState([]);
  const [imagesOther, setImagesOther] = useState([]);
  const maxNumber = 10;
  const [uploading, setUploading] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const onChangeBathroom = (imageList, addUpdateIndex) => {
    setImagesBathRoom(imageList);
  };
  const onChangeKitchen = (imageList, addUpdateIndex) => {
    setImagesKitchen(imageList);
  };
  const onChangeBedRoom = (imageList, addUpdateIndex) => {
    setImagesBedRoom(imageList);
  };
  const onChangeLivingRoom = (imageList, addUpdateIndex) => {
    setImagesLivingRoom(imageList);
  };
  const onChangeOther = (imageList, addUpdateIndex) => {
    setImagesOther(imageList);
  };
  const checkBathroom = (event) => {
    var value = event.target.value;
    if (event.target.checked) {
      setIsCameraOpen2(value);
    } else if (!event.target.checked) {
      setIsCameraOpen2(false);
    }
  };
  const checkKitchen = (event) => {
    var value = event.target.value;
    if (event.target.checked) {
      setIsCameraOpen3(value);
    } else if (!event.target.checked) {
      setIsCameraOpen3(false);
    }
  };
  const checkBedRoom = (event) => {
    var value = event.target.value;
    if (event.target.checked) {
      setIsCameraOpen4(value);
    } else if (!event.target.checked) {
      setIsCameraOpen4(false);
    }
  };
  const checkLivingRoom = (event) => {
    var value = event.target.value;
    if (event.target.checked) {
      setIsCameraOpen5(value);
    } else if (!event.target.checked) {
      setIsCameraOpen5(false);
    }
  };
  const checkOther = (event) => {
    var value = event.target.value;
    if (event.target.checked) {
      setIsCameraOpen6(value);
    } else if (!event.target.checked) {
      setIsCameraOpen6(false);
    }
  };

  const handlePhoto = async () => {
    for (let i = 0; i < images.length; i++) {
      const formData = new FormData();
      let image_as_files = images[i]["file"];
      formData.append("photo_name", image_as_files);
      formData.append("id_appraisal", idAppraisal);
      await axios
        .post(APIRoute.photo + idAppraisal, formData)
        .then(({ data }) => {
          createMetadata(data["id_photo"]["id_photo"], 1);
        })
        .catch(({ response }) => {
          setUploading(0);
          if (response.status) {
            Swal.fire({
              text: "error subiendo foto fachada",
              icon: "error",
            });
          }
        });
    }
    handlePhotoBathRoom();
  };
  const handlePhotoBathRoom = async () => {
    for (let j = 0; j < imagesBathRoom.length; j++) {
      const formData = new FormData();
      let image_as_bath = imagesBathRoom[j]["file"];
      formData.append("photo_name", image_as_bath);
      formData.append("id_appraisal", idAppraisal);
      await axios
        .post(APIRoute.photo + idAppraisal, formData)
        .then(({ data }) => {
          createMetadata(data["id_photo"]["id_photo"], 2);
        })
        .catch(({ response }) => {
          setUploading(0);
          if (response.status) {
            Swal.fire({
              text: "error subiendo foto baño",
              icon: "error",
            });
          }
        });
    }
    handlePhotoKitchen();
  };
  const handlePhotoKitchen = async () => {
    for (let k = 0; k < imagesKitchen.length; k++) {
      const formData = new FormData();
      let image_as_kit = imagesKitchen[k]["file"];
      formData.append("photo_name", image_as_kit);
      formData.append("id_appraisal", idAppraisal);
      await axios
        .post(APIRoute.photo + idAppraisal, formData)
        .then(({ data }) => {
          createMetadata(data["id_photo"]["id_photo"], 3);
        })
        .catch(({ response }) => {
          setUploading(0);
          if (response.status) {
            Swal.fire({
              text: "error subiendo foto cocina",
              icon: "error",
            });
          }
        });
    }
    handlePhotoBedRoom();
  };
  const handlePhotoBedRoom = async () => {
    for (let l = 0; l < imagesBedRoom.length; l++) {
      const formData = new FormData();
      let image_as_bed = imagesBedRoom[l]["file"];
      formData.append("photo_name", image_as_bed);
      formData.append("id_appraisal", idAppraisal);
      await axios
        .post(APIRoute.photo + idAppraisal, formData)
        .then(({ data }) => {
          createMetadata(data["id_photo"]["id_photo"], 4);
        })
        .catch(({ response }) => {
          setUploading(0);
          if (response.status) {
            Swal.fire({
              text: "error subiendo foto dormitorio",
              icon: "error",
            });
          }
        });
    }
    handlePhotoLivingRoom();
  };
  const handlePhotoLivingRoom = async () => {
    for (let m = 0; m < imagesLivingRoom.length; m++) {
      const formData = new FormData();
      let image_as_living = imagesLivingRoom[m]["file"];
      formData.append("photo_name", image_as_living);
      formData.append("id_appraisal", idAppraisal);
      await axios
        .post(APIRoute.photo + idAppraisal, formData)
        .then(({ data }) => {
          createMetadata(data["id_photo"]["id_photo"], 5);
        })
        .catch(({ response }) => {
          setUploading(0);
          if (response.status) {
            Swal.fire({
              text: "error subiendo foto living",
              icon: "error",
            });
          }
        });
    }
    handlePhotoOther();
  };
  const handlePhotoOther = async () => {
    for (let n = 0; n < imagesOther.length; n++) {
      const formData = new FormData();
      let image_as_other = imagesOther[n]["file"];
      formData.append("photo_name", image_as_other);
      formData.append("id_appraisal", idAppraisal);
      await axios
        .post(APIRoute.photo + idAppraisal, formData)
        .then(({ data }) => {
          createMetadata(data["id_photo"]["id_photo"], 6);
        })
        .catch(({ response }) => {
          setUploading(0);
          if (response.status === 404) {
            Swal.fire({
              text: "error subiendo foto Otros",
              icon: "error",
            });
          }
        });
    }
    sessionStorage.setItem("Fachada", 1);
    setStatus(true);
    setUploading(0);
  };
  const createMetadata = (id_photo, idClassification) => {
    const formData = new FormData();
    formData.append("id_photo", id_photo);
    formData.append("id_classification", idClassification);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    axios.post(APIRoute.meta, formData).catch(({ response }) => {
      if (response) {
      } else {
        Swal.fire({
          text: "error metadata",
          icon: "error",
        });
      }
    });
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-sm-12 col-md-10">
          <div className="progress my-3" style={{ height: "30px" }}>
            <div
              className="progress-bar-grey"
              role="progressbar"
              style={{ width: "100%", textAlign: "center" }}
              aria-label="Paso 2"
              aria-valuenow="70"
              aria-valuemin="56"
              aria-valuemax="70"
            >
              Paso 1
            </div>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "100%" }}
              aria-label="Paso 3"
              aria-valuenow="85"
              aria-valuemin="71"
              aria-valuemax="85"
            >
              Paso 2
            </div>
            <div
              className="progress-bar-grey"
              role="progressbar"
              style={{ width: "100%", textAlign: "center" }}
              aria-label="Paso 4"
              aria-valuenow="100"
              aria-valuemin="86"
              aria-valuemax="100"
            >
              Paso 3
            </div>
          </div>
          <div className="card">
            <div className="card-body bg-light bg-gradient">
              <h4 className="card-title" style={{ textAlign: "center" }}>
                Fotos de la propiedad
              </h4>
              <div style={{ textAlign: "center" }}>
                <Button onClick={handleShow} variant="info">
                  Ejemplos fotografia
                </Button>
              </div>
              <hr />
              <div className="form-wrapper">
                <Row>
                  <Col>
                    <Fragment>
                      <Form.Label>Foto Fachada (obligatoria)</Form.Label>
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        acceptType={["jpg", "jpeg", "png"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          <div className="upload__image-wrapper">
                            <Button
                              style={isDragging ? { color: "red" } : null}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Presiona para ingresar Fotos
                            </Button>
                            <Button variant="danger" onClick={onImageRemoveAll}>
                              Eliminar todas las imágenes
                            </Button>
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <Image
                                  src={image.data_url}
                                  alt=""
                                  width="60%"
                                />
                                <div className="image-item__btn-wrapper ">
                                  <Button onClick={() => onImageUpdate(index)}>
                                    Actualizar
                                  </Button>
                                  <Button
                                    variant="danger"
                                    onClick={() => onImageRemove(index)}
                                  >
                                    Eliminar
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </ImageUploading>
                      {idTypeAsset == 1 ?
                      <Modal show={showModal} onHide={handleClose}>
                        <Carousel variant="dark"  style={{backgroundColor: "#171717", height: "300px",
                                        margin: "5px",}}>
                          <Carousel.Item>
                            <img
                            style={{height:"300px"}}
                              className="d-block w-100 img-fluid rounded"
                              src={APIRoute.URL + "examplephoto/fachada.jpeg"}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <h3 style={{ backgroundColor: "rgba(0,0,0,0.4)"}}>Fachada</h3>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                            style={{height:"300px"}}
                              className="d-block w-100"
                              src={APIRoute.URL + "examplephoto/baño.jpg"}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <h3 style={{backgroundColor: "rgba(0,0,0,0.475)"}}>Baños</h3>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                            style={{height:"300px"}}
                              className="d-block w-100"
                              src={APIRoute.URL + "examplephoto/cocina.jpg"}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <h3 style={{backgroundColor: "rgba(0,0,0,0.4)"}}>Cocina</h3>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                            style={{height:"300px"}}
                              className="d-block w-100"
                              src={APIRoute.URL + "examplephoto/dormitorio.jpg"}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <h3 style={{backgroundColor: "rgba(0,0,0,0.4)"}}>Dormitorio</h3>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                            style={{height:"300px"}}
                              className="d-block w-100"
                              src={APIRoute.URL + "examplephoto/sala.jpg"}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <h3 style={{backgroundColor: "rgba(0,0,0,0.4)"}}>Sala de estar</h3>
                            </Carousel.Caption>
                          </Carousel.Item>
                        </Carousel>
                      </Modal>
                      : 
                      <Modal show={showModal} onHide={handleClose}>
                        <Carousel style={{backgroundColor: "#171717", height: "300px",
                                        margin: "5px" ,position: "absolute", top: "30vh"}}>
                          <Carousel.Item>
                            <img
                            style={{height:"300px"}}
                              className="d-block w-100 img-fluid rounded"
                              src={APIRoute.URL + "examplephoto/edificio.jpg"}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <h3 style={{ backgroundColor: "rgba(0,0,0,0.4)"}}>Fachada</h3>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                            style={{height:"300px"}}
                              className="d-block w-100"
                              src={APIRoute.URL + "examplephoto/baño.jpg"}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <h3 style={{backgroundColor: "rgba(0,0,0,0.475)"}}>Baños</h3>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                            style={{height:"300px"}}
                              className="d-block w-100"
                              src={APIRoute.URL + "examplephoto/cocinadep.jpg"}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <h3 style={{backgroundColor: "rgba(0,0,0,0.4)"}}>Cocina</h3>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                            style={{height:"300px"}}
                              className="d-block w-100"
                              src={APIRoute.URL + "examplephoto/dormitorio.jpg"}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <h3 style={{backgroundColor: "rgba(0,0,0,0.4)"}}>Dormitorio</h3>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                            style={{height:"300px"}}
                              className="d-block w-100"
                              src={APIRoute.URL + "examplephoto/sala.jpg"}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <h3 style={{backgroundColor: "rgba(0,0,0,0.4)"}}>Sala de estar</h3>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                            style={{height:"300px"}}
                              className="d-block w-100"
                              src={APIRoute.URL + "examplephoto/terraza.png"}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <h3 style={{backgroundColor: "rgba(0,0,0,0.4)"}}>Terraza</h3>
                            </Carousel.Caption>
                          </Carousel.Item>
                        </Carousel>
                      </Modal>}
                    </Fragment>
                  </Col>
                </Row>
                <hr />
                <Form.Label>
                  Seleccione casilla del tipo de bien (10 maximo por cada uno):
                </Form.Label>
                <Row className="my-4">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="md-10"
                      controlId="fileNumber"
                    >
                      <Col sm="12">
                        <Col className="row alig-content-center">
                          <Form.Label
                            className="col-md-6 row"
                            style={{
                              paddingTop: "4px",
                              marginLeft: "1px",
                              width: "60%",
                            }}
                          >
                            Baños
                          </Form.Label>
                          <Form.Check
                            className="col-md-6 row "
                            style={{ width: "20px", fontSize: "150%" }}
                            type="checkbox"
                            value="check2"
                            onClick={checkBathroom}
                          />
                          <Col className="center" sm="10">
                            <Fragment>
                              {isCameraOpen2 && (
                                <ImageUploading
                                  multiple
                                  value={imagesBathRoom}
                                  onChange={onChangeBathroom}
                                  maxNumber={maxNumber}
                                  dataURLKey="data_url"
                                  acceptType={["jpg", "jpeg", "png"]}
                                >
                                  {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                  }) => (
                                    <div className="upload__image-wrapper center">
                                      <Button
                                        style={
                                          isDragging ? { color: "red" } : null
                                        }
                                        onClick={onImageUpload}
                                        {...dragProps}
                                      >
                                        Presiona para ingresar Fotos
                                      </Button>
                                      <Button
                                        variant="danger"
                                        onClick={onImageRemoveAll}
                                      >
                                        Eliminar todas las imágenes
                                      </Button>
                                      {imageList.map((image, index) => (
                                        <div
                                          key={index}
                                          className="image-item center"
                                        >
                                          <Image
                                            src={image.data_url}
                                            alt=""
                                            width="60%"
                                          />
                                          <div className="image-item__btn-wrapper ">
                                            <Button
                                              onClick={() => {
                                                onImageUpdate(index);
                                              }}
                                            >
                                              Actualizar
                                            </Button>
                                            <Button
                                              variant="danger"
                                              onClick={() =>
                                                onImageRemove(index)
                                              }
                                            >
                                              Eliminar
                                            </Button>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </ImageUploading>
                              )}
                              {isCameraOpen2 ? (
                                <div>
                                  <Form.Label
                                    style={{ fontSize: 23, padding: 1 }}
                                  >
                                    Fotos baños
                                  </Form.Label>
                                </div>
                              ) : (
                                <> </>
                              )}
                            </Fragment>
                          </Col>
                        </Col>
                        <hr />
                        <Col className="row alig-content-center">
                          <Form.Label
                            className="col-md-6 row"
                            style={{
                              paddingTop: "4px",
                              marginLeft: "1px",
                              width: "60%",
                            }}
                          >
                            Cocina
                          </Form.Label>
                          <Form.Check
                            className="col-md-6 row"
                            style={{ width: "20px", fontSize: "150%" }}
                            type="checkbox"
                            value="check3"
                            onClick={checkKitchen}
                          />
                          <Col className="center" sm="10">
                            <Fragment>
                              {isCameraOpen3 && (
                                <ImageUploading
                                  multiple
                                  value={imagesKitchen}
                                  onChange={onChangeKitchen}
                                  maxNumber={maxNumber}
                                  dataURLKey="data_url"
                                  acceptType={["jpg", "jpeg", "png"]}
                                >
                                  {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                  }) => (
                                    <div className="upload__image-wrapper center">
                                      <Button
                                        style={
                                          isDragging ? { color: "red" } : null
                                        }
                                        onClick={onImageUpload}
                                        {...dragProps}
                                      >
                                        Presiona para ingresar Fotos
                                      </Button>
                                      <Button
                                        variant="danger"
                                        onClick={onImageRemoveAll}
                                      >
                                        Eliminar todas las imágenes
                                      </Button>
                                      {imageList.map((image, index) => (
                                        <div
                                          key={index}
                                          className="image-item center"
                                        >
                                          <Image
                                            src={image.data_url}
                                            alt=""
                                            width="60%"
                                          />
                                          <div className="image-item__btn-wrapper ">
                                            <Button
                                              onClick={() => {
                                                onImageUpdate(index);
                                              }}
                                            >
                                              Actualizar
                                            </Button>
                                            <Button
                                              variant="danger"
                                              onClick={() =>
                                                onImageRemove(index)
                                              }
                                            >
                                              Eliminar
                                            </Button>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </ImageUploading>
                              )}
                              {isCameraOpen3 ? (
                                <div>
                                  <Form.Label
                                    style={{ fontSize: 23, padding: 1 }}
                                  >
                                    Fotos Cocina
                                  </Form.Label>
                                </div>
                              ) : (
                                <></>
                              )}
                            </Fragment>
                          </Col>
                        </Col>
                        <hr />
                        <Col className="row alig-content-center">
                          <Form.Label
                            className="col-md-6 row"
                            style={{
                              paddingTop: "4px",
                              marginLeft: "1px",
                              width: "60%",
                            }}
                          >
                            Dormitorio
                          </Form.Label>
                          <Form.Check
                            className="col-md-6 row"
                            style={{ width: "20px", fontSize: "150%" }}
                            type="checkbox"
                            value="check4"
                            onClick={checkBedRoom}
                          />
                          <Col className="center" sm="10">
                            <Fragment>
                              {isCameraOpen4 && (
                                <ImageUploading
                                  multiple
                                  value={imagesBedRoom}
                                  onChange={onChangeBedRoom}
                                  maxNumber={maxNumber}
                                  dataURLKey="data_url"
                                  acceptType={["jpg", "jpeg", "png"]}
                                >
                                  {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                  }) => (
                                    <div className="upload__image-wrapper center">
                                      <Button
                                        style={
                                          isDragging ? { color: "red" } : null
                                        }
                                        onClick={onImageUpload}
                                        {...dragProps}
                                      >
                                        Presiona para ingresar Fotos
                                      </Button>
                                      <Button
                                        variant="danger"
                                        onClick={onImageRemoveAll}
                                      >
                                        Eliminar todas las imágenes
                                      </Button>
                                      {imageList.map((image, index) => (
                                        <div
                                          key={index}
                                          className="image-item center"
                                        >
                                          <Image
                                            src={image.data_url}
                                            alt=""
                                            width="60%"
                                          />
                                          <div className="image-item__btn-wrapper ">
                                            <Button
                                              onClick={() => {
                                                onImageUpdate(index);
                                              }}
                                            >
                                              Actualizar
                                            </Button>
                                            <Button
                                              variant="danger"
                                              onClick={() =>
                                                onImageRemove(index)
                                              }
                                            >
                                              Eliminar
                                            </Button>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </ImageUploading>
                              )}
                              {isCameraOpen4 ? (
                                <div>
                                  <Form.Label
                                    style={{ fontSize: 23, padding: 1 }}
                                  >
                                    Fotos dormitorios
                                  </Form.Label>
                                </div>
                              ) : (
                                <></>
                              )}
                            </Fragment>
                          </Col>
                        </Col>
                        <hr />
                        <Col className="row alig-content-center">
                          <Form.Label
                            className="col-md-6 row"
                            style={{
                              paddingTop: "4px",
                              marginLeft: "1px",
                              width: "60%",
                            }}
                          >
                            Sala de estar
                          </Form.Label>
                          <Form.Check
                            className="col-md-6 row"
                            style={{ width: "20px", fontSize: "150%" }}
                            type="checkbox"
                            value="check5"
                            onClick={checkLivingRoom}
                          />
                          <Col className="center" sm="10">
                            <Fragment>
                              {isCameraOpen5 && (
                                <ImageUploading
                                  multiple
                                  value={imagesLivingRoom}
                                  onChange={onChangeLivingRoom}
                                  maxNumber={maxNumber}
                                  dataURLKey="data_url"
                                  acceptType={["jpg", "jpeg", "png"]}
                                >
                                  {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                  }) => (
                                    <div className="upload__image-wrapper center">
                                      <Button
                                        style={
                                          isDragging ? { color: "red" } : null
                                        }
                                        onClick={onImageUpload}
                                        {...dragProps}
                                      >
                                        Presiona para ingresar Fotos
                                      </Button>
                                      <Button
                                        variant="danger"
                                        onClick={onImageRemoveAll}
                                      >
                                        Eliminar todas las imágenes
                                      </Button>
                                      {imageList.map((image, index) => (
                                        <div
                                          key={index}
                                          className="image-item center"
                                        >
                                          <Image
                                            src={image.data_url}
                                            alt=""
                                            width="60%"
                                          />
                                          <div className="image-item__btn-wrapper ">
                                            <Button
                                              onClick={() => {
                                                onImageUpdate(index);
                                              }}
                                            >
                                              Actualizar
                                            </Button>
                                            <Button
                                              variant="danger"
                                              onClick={() =>
                                                onImageRemove(index)
                                              }
                                            >
                                              Eliminar
                                            </Button>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </ImageUploading>
                              )}
                              {isCameraOpen5 ? (
                                <div>
                                  <Form.Label
                                    style={{ fontSize: 23, padding: 1 }}
                                  >
                                    Fotos sala de estar
                                  </Form.Label>
                                </div>
                              ) : (
                                <></>
                              )}
                            </Fragment>
                          </Col>
                        </Col>
                        <hr />
                        <Col className="row alig-content-center">
                          <Form.Label
                            className="col-md-6 row"
                            style={{
                              paddingTop: "4px",
                              marginLeft: "1px",
                              width: "60%",
                            }}
                          >
                            Otros
                          </Form.Label>
                          <Form.Check
                            className="col-md-6 row"
                            style={{ width: "20px", fontSize: "150%" }}
                            type="checkbox"
                            value="check6"
                            onClick={checkOther}
                          />
                          <Col className="center" sm="10">
                            <Fragment>
                              {isCameraOpen6 && (
                                <ImageUploading
                                  multiple
                                  value={imagesOther}
                                  onChange={onChangeOther}
                                  maxNumber={maxNumber}
                                  dataURLKey="data_url"
                                  acceptType={["jpg", "jpeg", "png"]}
                                >
                                  {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                  }) => (
                                    <div className="upload__image-wrapper center">
                                      <Button
                                        style={
                                          isDragging ? { color: "red" } : null
                                        }
                                        onClick={onImageUpload}
                                        {...dragProps}
                                      >
                                        Presiona para ingresar Fotos
                                      </Button>
                                      <Button
                                        variant="danger"
                                        onClick={onImageRemoveAll}
                                      >
                                        Eliminar todas las imágenes
                                      </Button>
                                      {imageList.map((image, index) => (
                                        <div
                                          key={index}
                                          className="image-item center"
                                        >
                                          <Image
                                            src={image.data_url}
                                            alt=""
                                            width="60%"
                                          />
                                          <div className="image-item__btn-wrapper ">
                                            <Button
                                              onClick={() => {
                                                onImageUpdate(index);
                                              }}
                                            >
                                              Actualizar
                                            </Button>
                                            <Button
                                              variant="danger"
                                              onClick={() =>
                                                onImageRemove(index)
                                              }
                                            >
                                              Eliminar
                                            </Button>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </ImageUploading>
                              )}
                              {isCameraOpen6 ? (
                                <div>
                                  <Form.Label
                                    style={{ fontSize: 23, padding: 1 }}
                                  >
                                    Otros
                                  </Form.Label>
                                </div>
                              ) : (
                                <></>
                              )}
                            </Fragment>
                          </Col>
                        </Col>
                        <hr />
                        <Alert variant="warning">
                          Asegurese de revisar y subir <b>TODAS</b> las fotos y
                          revisar bien antes de continuar (<b>No</b> se puede
                          volver atras, asegurese de confirmar sus fotos)
                        </Alert>
                        <Button
                          variant="success"
                          disabled={status}
                          onClick={() => handlePhoto()}
                        >
                          {uploading ? (
                            <div>
                              <Spinner
                                size="md"
                                animation="border"
                                role="status"
                                variant="warning"
                              />
                            </div>
                          ) : (
                            <>Cargar todas las fotos</>
                          )}
                        </Button>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
