import React, { useState } from "react";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../logo.svg";
import Button from "react-bootstrap/Button";
import { FormUser } from "../appraisal/Form";
import Authentication from "../Auth/Authentication";
import { Spinner } from "react-bootstrap";

export default function Access() {
  const [uName, setUName] = useState("");
  const [uPass, setUPass] = useState("");
  let [validationError, setValidationError] = useState({});
  const [show, setShow] = useState(0);

  const handleSubmit = async (e) => {
    const formData = new FormData();
    formData.append("uName", uName);
    formData.append("uPass", uPass);

    await axios.post(APIRoute.access, formData).then(({ data }) => {
      if (data.status === 200) {
        setShow(0);
        sessionStorage.setItem("session", 1);
        sessionStorage.setItem("typeLogin", data.typeLogin);
        sessionStorage.setItem("idUser", data.idUser);
        sessionStorage.setItem("idAppraisal", data.idAppraisal);
        sessionStorage.setItem("sessionHidden", 1);
        window.location.reload();
      } else if (data.status === 401) {
        setShow(0);
        setValidationError("Código ya utilizado");
      } else if (data.status === 400) {
        setShow(0);
        setValidationError("Correo o código inválidos");
      } else if (data.status === 500) {
        setShow(0);
        setValidationError("No existe conexión al servicio");
      }
    });
  };

  return (
    <>
      {sessionStorage.getItem("session") === 0 ||
      sessionStorage.getItem("session") === null ? (
        <div className="imgfondo">
          <div className="containerlogin">
            <div className="formcontainer2">
              <div className="card" style={{ background: "#F0F0F0" }}>
                <div
                  className="text-center align-items-center"
                  style={{ margin: "20px" }}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      bottom: "10px",
                      right: "10px",
                      width: "100px",
                      margin: 10,
                    }}
                  />
                  <h4>Bienvenido al Sistema de Tasación por Fotografías</h4>
                </div>
                <div className="text-center align-items-center">
                  <hr />
                  <h5>Ingrese sus datos por favor</h5>
                  <div className="form-wrapper">
                    {Object.keys(validationError).length > 0 && (
                      <div className="row">
                        <div className="col-12">
                          <div className="alert alert-danger">
                            <li>{validationError}</li>
                          </div>
                        </div>
                      </div>
                    )}
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Form.Group
                            controlId="checkName"
                            className="ml-2 mr-2"
                          >
                            <Form.Label>Correo electronico</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Ingrese su correo electronico"
                              value={uName}
                              onChange={(event) => {
                                setUName(event.target.value);
                              }}
                              style={{ opacity: "1" }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col>
                          <Form.Group controlId="uPass" className="ml-2 mr-2">
                            <Form.Label>Código de acceso</Form.Label>
                            <Form.Control
                              required
                              type="password"
                              autoComplete="on"
                              placeholder="Ingrese su contraseña"
                              value={uPass}
                              onChange={(event) => {
                                setUPass(event.target.value);
                              }}
                              style={{ opacity: "1" }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button
                        onClick={() => [setShow(1), handleSubmit()]}
                        disabled={show}
                        variant="primary"
                        className="mb-2"
                        size="lg"
                        block="block"
                        type="submit"
                        style={{ opacity: "1" }}
                      >
                        {show ? (
                          <div>
                            <Spinner
                              size="lg"
                              animation="border"
                              role="status"
                              variant="warning"
                            />
                          </div>
                        ) : (
                          <>Ingresar</>
                        )}
                      </Button>
                      <br />
                      <div className="footer text-center">
                        <p>
                          © 2022 Valuaciones de Chile. Todos los Derechos
                          Reservados
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {sessionStorage.getItem("typeLogin") === "client" ? (
            <FormUser />
          ) : (
            <Authentication />
          )}
        </>
      )}
    </>
  );
}
