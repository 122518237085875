import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import Swal from "sweetalert2";
import { MDBDataTableV5 } from "mdbreact";
import { GoEye, GoChecklist } from "react-icons/go";
import { Spinner } from "react-bootstrap";

export default function AppraiserList() {
  const [appraisalList, setAppraisalList] = useState([]);
  const [show, setShow] = useState(0);
  var [idAppraiser] = useState(sessionStorage.getItem("id"));

  const headCells = [
    
    {
      label: "Nombre cliente",
      field: "nameClient",
    },
    
    {
      label: "Teléfono Cliente",
      field: "phoneClient",
    },
    
    {
      label: "Correo Cliente",
      field: "emailClient",
    },
    {
      label: "Rol",
      field: "rol",
    },
    {
      label: "Dirección",
      field: "address",
    },
    {
      label: "Nombre coordinadora",
      field: "nameCoordinator",
    },
    {
      label: "Entrega Cliente",
      field: "status",
    },
    {
      label: "Estado Visación",
      field: "statusAppraiser",
    },
    {
      label: "Acción",
      field: "actionBtn",
    },
  ];
  const [datatable, setDatatable] = useState({
    columns: headCells,
    rows: [],
  });
  useEffect(() => {
    fetchAppraisal();
  }, []);

  const visaAppraiser = async (id) => {
    const { value: file } = await Swal.fire({
      title: "Ingrese PDF para informe",
      input: "file",
      inputAttributes: {
        accept: "PDF",
      },
    });
    if (file) {
      const formData = new FormData();
      formData.append("id_appraisal", id);
      formData.append("name", file);
      await axios.post(APIRoute.appraiserPdf, formData).then(() => {
        const formData = new FormData();
        formData.append("idAppraisal", id);
        axios.post(APIRoute.visaAppraiser, formData).then(() => {
          Swal.fire({
            icon: "success",
            text: "Tasación visada correctamente",
          });
          fetchAppraisal();
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            text: "Error al visar tasación",
          });
        })
      })
    } else {
      Swal.fire("Cambios no guardados", "", "warning")
    }
  };

  const fetchAppraisal = () => {
    setShow(1);
    axios.get(APIRoute.appraisal).then(({ data }) => {
      setAppraisalList(data);
      setDatatable({
        columns: headCells,
        rows: data.map((appraisal) => {
          let status = "";
          let statusAppraiser ="";
          if (appraisal.status === 0) {
            status = "PENDIENTE";
          } else {
            status = "REALIZADA";
          }
          if(appraisal.status_appraiser === 1){
            statusAppraiser = "VISADO";
          }else{
            statusAppraiser = "NO VISADO";
          }
          let reportstatus = "";
          if (appraisal.reportstatus === 1) {
            reportstatus = "VISADA";
          } else {
            reportstatus = "SIN VISAR";
          }
          if (reportstatus === "SIN VISAR" && parseInt(idAppraiser) === appraisal.id_appraiser ) {
            return {
              id: appraisal.id_appraisal,
              address: appraisal.address,
              rol: appraisal.rol,
              nameClient: appraisal.name_client,
              clientDesc: appraisal.client_description,
              nameCoordinator: appraisal.name_coordinator,
              phoneClient: appraisal.phone_client,
              emailClient: appraisal.email_client,
              statusAppraiser: statusAppraiser,
              status: status,
              actionBtn: (
                <>
                  <div
                    className="d-grid col-md-6 col-lg-9 col-sm-3"
                    style={{ fontSize: "12px" }}
                  >
                    <Link to={APIRoute.editAppraisal + appraisal.id_appraisal}>
                      <Button title="Ver detalles" className="btn btn-warning">
                        <GoEye style={{ margin: 0, fontSize: "20px" }} />
                      </Button>
                    </Link>
                      <Button 
                      disabled={appraisal.status_appraiser}
                      title="Visar" 
                      className="btn btn-primary" 
                      onClick={()=>visaAppraiser(appraisal.id_appraisal)}>
                        <GoChecklist style={{ margin: 0, fontSize: "20px" }} />
                      </Button>
                  </div>
                </>
              ),
            };
          }
        }),
      });
    });
  };
  return (
    <div className="container">
      <h3 style={{ display: "flex", justifyContent: "center" }}>
        Tasaciones por visar
      </h3>
      <div className="row">
        <div className="col-12">
          {show ? (
            <MDBDataTableV5
              hover
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              searchTop
              searchBottom={false}
              searchLabel="Busqueda general"
              exportToCSV
              responsive
              data={datatable}
            />
          ) : (
            <div>
              <Spinner animation="border" role="status"></Spinner>
              <span className="">Cargando datos...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
