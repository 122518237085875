import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams, Link } from "react-router-dom";
import APIRoute from "../routersAPI/routes.json";
import Accordion from "react-bootstrap/Accordion";
import { Spinner } from "react-bootstrap";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";

export default function AppraiserDetails() {
  const { id } = useParams();
  let [namePhoto] = useState([]);
  let [namePhoto2] = useState([]);
  let [namePhoto3] = useState([]);
  let [namePhoto4] = useState([]);
  let [namePhoto5] = useState([]);
  let [namePhoto6] = useState([]);
  const [doc, setDoc] = useState([]);
  const [doc2, setDoc2] = useState([]);
  const [doc3, setDoc3] = useState([]);
  const [doc4, setDoc4] = useState([]);
  const [rut_client, setRutClient] = useState("");
  const [name_client, setNameClient] = useState("");
  const [email_client, setEmailClient] = useState("");
  const [phone_client, setPhoneClient] = useState("");
  const [address, setAddress] = useState("");
  const [idCommune, setCommune] = useState("");
  const [region, setRegion] = useState("");
  const [communeList, setCommuneList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [typeOfAssetList, setTypeOfAssetList] = useState([]);
  const [checkListTypeOfAssetsList, setCheckListTypeOfAssetsList] = useState(
    []
  );
  const [errorMessage, setErrorMessage] = useState({});
  const [idTypeOfAsset, setIdTypeOfAsset] = useState("");
  const [rol, setRol] = useState([]);
  const [bathroom, setBathroom] = useState([]);
  const [bedroom, setBedroom] = useState([]);
  const [terrainArea, setTerrainArea] = useState([]);
  const [constructionArea, setConstructionArea] = useState([]);
  const [descClient, setDescClient] = useState([]);
  const [reportStatus, setReportStatus] = useState(0);
  const [validationError, setValidationError] = useState({});
  const [show, setShow] = useState(0);
  const [allPhotosArray] = useState([]);

  useEffect(() => {
    fetchRegionList();
    fetchAppraisalData();
    fetchTypeOfAssetList();
    fetchComplementary();
    handlePhoto();
    handleDoc();
  }, []);

  const fetchClient = async (id_client) => {
    await axios.get(APIRoute.clients + id_client).then(({ data }) => {
      setShow(1);
      if (data.client !== null) {
        setRutClient(data.client["rut_client"]);
        setNameClient(data.client["name_client"]);
        setPhoneClient(data.client["phone_client"]);
        setEmailClient(data.client["email_client"]);
      } else {
        setValidationError("El cliente no se encuentra o fue eliminado");
      }
    });
  };
  const fetchComplementary = async () => {
    await axios.get(APIRoute.compcontroller + id).then(({ data }) => {
      setCheckListTypeOfAssetsList(data);
    });
  };
  const fetchRegionList = () => {
    axios.get(APIRoute.region).then(({ data }) => {
      setRegionList(data);
    });
  };
  const fetchAppraisalData = async () => {
    let dataR = "";
    await axios
      .get(APIRoute.appraisal + id)
      .then(({ data }) => {
        fetchClient(data["id_client"]);
        setAddress(data["address"]);
        setRegion(data["id_region"]);
        dataR = data["id_region"];
        setCommune(data["id_commune"]);
        setIdTypeOfAsset(data["id_type_of_assets"]);
        setBedroom(data["bedrooms"]);
        setBathroom(data["bathrooms"]);
        setRol(data["rol"]);
        setTerrainArea(data["terrain_area"]);
        setConstructionArea(data["construction_area"]);
        setDescClient(data["client_description"]);
        setReportStatus(data["reportstatus"]);
      })
      .finally(() => {
        axios.get(APIRoute.commune + dataR).then(({ data }) => {
          setCommuneList(data);
        });
      });
  };
  const handleCommune = async (idRegion) => {
    await axios
      .get(APIRoute.commune + idRegion)
      .then(({ data }) => {
        setCommuneList(data);
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setErrorMessage(response.data.errors);
        }
      });
  };
  const handleAsset = async (idTypeOfAssets) => {
    setIdTypeOfAsset(idTypeOfAssets);
    await axios
      .get(APIRoute.complement + idTypeOfAssets)
      .then(({ data }) => {
        setCheckListTypeOfAssetsList(data);
      })
      .catch(({ response }) => {
        setErrorMessage(response.data.errors);
      });
  };
  const fetchTypeOfAssetList = async () => {
    await axios.get(APIRoute.asset).then(({ data }) => {
      setTypeOfAssetList(data);
    });
  };
  const handlePhoto = async () => {
    await axios
      .get(APIRoute.photo + id)
      .then(({ data }) => {
        for (let i = 0; i < data.length; i++) {
          //allPhotosArray[i].push = data[i];
          if (data[i].id_classification === 1) {
            if (!namePhoto.includes(APIRoute.URL + data[i].photo_name)) {
              namePhoto.push(APIRoute.URL + data[i].photo_name);
            }
          }
          if (data[i].id_classification === 2) {
            if (!namePhoto2.includes(APIRoute.URL + data[i].photo_name)) {
              namePhoto2.push(APIRoute.URL + data[i].photo_name);
            }
          }
          if (data[i].id_classification === 3) {
            if (!namePhoto3.includes(APIRoute.URL + data[i].photo_name)) {
              namePhoto3.push(APIRoute.URL + data[i].photo_name);
            }
          }
          if (data[i].id_classification === 4) {
            if (!namePhoto4.includes(APIRoute.URL + data[i].photo_name)) {
              namePhoto4.push(APIRoute.URL + data[i].photo_name);
            }
          }
          if (data[i].id_classification === 5) {
            if (!namePhoto5.includes(APIRoute.URL + data[i].photo_name)) {
              namePhoto5.push(APIRoute.URL + data[i].photo_name);
            }
          }
          if (data[i].id_classification === 6) {
            if (!namePhoto6.includes(APIRoute.URL + data[i].photo_name)) {
              namePhoto6.push(APIRoute.URL + data[i].photo_name);
            }
          }
        }
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
        }
      })
      .finally(() => {
        return allPhotosArray;
      });
  };
  const handleDoc = async (e) => {
    await axios
      .get(APIRoute.doc + id)
      .then(({ data }) => {
        setDoc(APIRoute.URL + data[0]["name_file"]);
        setDoc2(APIRoute.URL + data[1]["name_file"]);
        setDoc3(APIRoute.URL + data[2]["name_file"]);
        setDoc4(APIRoute.URL + data[3]["name_file"]);
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
        }
      })
      .finally(() => {});
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const fetchPhotos = async () => {
    var photo = [];
    var contador = 0;
    try {
      for (let p = 0; p < namePhoto.length; p++) {
        fetch(namePhoto[p]).then((response) => {
          response.blob().then((blob) => {
            photo.push(blob);
          });
        });
        contador = contador + 1;
      }
      await waitAsync();
      for (let p = 0; p < namePhoto2.length; p++) {
        fetch(namePhoto2[p]).then((response) => {
          response.blob().then((blob) => {
            photo.push(blob);
          });
        });
        contador = contador + 1;
      }
      await waitAsync();
      for (let p = 0; p < namePhoto3.length; p++) {
        fetch(namePhoto3[p]).then((response) => {
          response.blob().then((blob) => {
            photo.push(blob);
          });
        });
        contador = contador + 1;
      }
      await waitAsync();
      for (let p = 0; p < namePhoto4.length; p++) {
        fetch(namePhoto4[p]).then((response) => {
          response.blob().then((blob) => {
            photo.push(blob);
          });
        });
        contador = contador + 1;
      }
      await waitAsync();
      for (let p = 0; p < namePhoto5.length; p++) {
        fetch(namePhoto5[p]).then((response) => {
          response.blob().then((blob) => {
            photo.push(blob);
          });
        });
        contador = contador + 1;
      }
      await waitAsync();
      for (let p = 0; p < namePhoto6.length; p++) {
        fetch(namePhoto6[p]).then((response) => {
          response.blob().then((blob) => {
            photo.push(blob);
          });
        });
        contador = contador + 1;
      }
      await waitAsync();
    } catch (response) {
      Swal.fire({
        icon: "success",
        text: "Error al descargar las fotos",
      });
    } finally {
      downloadPhotos(photo, contador);
    }
  };
  const waitAsync = () => {
    return new Promise((res) => {
      setTimeout(() => {
        res();
      }, 100);
    });
  };
  const downloadPhotos = async (photo, contador) => {
    var zip = new JSZip();
    let p = photo.length - 1;
    while (p >= 0) {
      zip.file("Foto_num_" + contador + ".png", photo[p]);
      contador--;
      p--;
    }
    waitAsync();
    await zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(
        content,
        "Fotos_cliente_" + name_client + "_" + rut_client + ".zip"
      );
    });
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-12">
          <h4 className="card-title">Visualizar Datos Cliente</h4>
          <br />
          <div className="card">
            {show ? (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Datos Cliente</Accordion.Header>
                  <Accordion.Body>
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Datos Cliente</h4>
                        {Object.keys(validationError).length > 0 && (
                          <div className="row">
                            <div className="col-12">
                              <div className="alert alert-danger">
                                <p>{validationError}</p>
                              </div>
                            </div>
                          </div>
                        )}
                        <hr />
                        <div className="form-wrapper">
                          <Form>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="rut_client"
                                >
                                  <Form.Label column sm="2">
                                    Rut Cliente
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control
                                      disabled
                                      type="text"
                                      size="lg"
                                      placeholder="ej. 12345678-9"
                                      required
                                      value={rut_client}
                                      onChange={(event) => {
                                        setRutClient(event.target.value);
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="name_client"
                                >
                                  <Form.Label column sm="2">
                                    Nombre y Apellido Cliente
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control
                                     disabled
                                      type="text"
                                      size="lg"
                                      placeholder="Ingrese nombre y apellido Cliente"
                                      required
                                      value={name_client}
                                      onChange={(event) => {
                                        setNameClient(event.target.value);
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="phone_client"
                                >
                                  <Form.Label column sm="2">
                                    Telefono
                                  </Form.Label>

                                  <Col sm="10">
                                    <Row>
                                      <Col sm="2">
                                        <Form.Control
                                          disabled
                                          value="+ 56"
                                          size="lg"
                                        />
                                      </Col>
                                      <Col sm="10">
                                        <Form.Control
                                          disabled
                                          type="number"
                                          size="lg"
                                          placeholder="Ingrese Telefono"
                                          required
                                          value={phone_client}
                                          onChange={(event) => {
                                            setPhoneClient(event.target.value);
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="email_client"
                                >
                                  <Form.Label column sm="2">
                                    Correo
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control
                                      disabled
                                      type="text"
                                      size="lg"
                                      placeholder="Ingrese Correo"
                                      required
                                      value={email_client}
                                      onChange={(event) => {
                                        setEmailClient(event.target.value);
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Detalle tipo de bien</Accordion.Header>
                  <Accordion.Body>
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Detalle tipo de bien</h4>
                        <hr />
                        <div className="form-wrapper">
                          <Form>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="address"
                                >
                                  <Form.Label column sm="2">
                                    Dirección
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control
                                      disabled
                                      type="text"
                                      size="lg"
                                      placeholder="Ingrese dirección"
                                      required
                                      value={address}
                                      onChange={(event) => {
                                        setAddress(event.target.value);
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="region"
                                >
                                  <Form.Label column sm="2">
                                    Región
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Select
                                      disabled
                                      aria-label="Seleccionar Region"
                                      value={region}
                                      onChange={(e) => {
                                        setRegion(e.target.value);
                                        handleCommune(e.target.value);
                                      }}
                                    >
                                      <option
                                        value=""
                                        defaultValue
                                        disabled="active"
                                      >
                                        Seleccione Región
                                      </option>
                                      {regionList.map((item, index) => (
                                        <option
                                          key={index}
                                          value={item.id_region}
                                        >
                                          {item.name_region}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="idCommune"
                                  name="idCommune"
                                >
                                  <Form.Label column sm="2">
                                    Comuna
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Select
                                      disabled
                                      aria-label="Seleccionar comuna"
                                      value={idCommune}
                                      onChange={(event) => {
                                        setCommune(event.target.value);
                                      }}
                                    >
                                      <option
                                        value=""
                                        defaultValue
                                        disabled="disabled"
                                      >
                                        Seleccione tipo de comuna
                                      </option>
                                      {communeList.map((item, index) => (
                                        <option
                                          key={index}
                                          value={item.id_commune}
                                          onChange={() => {}}
                                        >
                                          {item.name_commune}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="idTypeOfAsset"
                                >
                                  <Form.Label column sm="2">
                                    Tipo de bien
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Select
                                      aria-label="Seleccionar tipo de bien"
                                      value={idTypeOfAsset}
                                      disabled
                                    >
                                      <option
                                        value=""
                                        defaultValue
                                        disabled="disabled"
                                      >
                                        Seleccione tipo de bien
                                      </option>
                                      {typeOfAssetList.map((item, index) => (
                                        <option
                                          key={index}
                                          value={item.id_type_of_assets}
                                        >
                                          {item.description}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="fileNumber"
                                >
                                  <Form.Label column sm="2">
                                    Complementos
                                  </Form.Label>
                                  <Col sm="10">
                                    {checkListTypeOfAssetsList.map(
                                      (item, index) => (
                                        <Col
                                          className="row alig-content-center"
                                          key={index}
                                        >
                                          <Form.Label
                                            className="col-9 col-md-9"
                                            value={item.id_desc_comp}
                                            hidden
                                          />
                                          <h6 className="col-6 row alig-content-center">
                                            {item.name_comp}
                                          </h6>
                                          <Form.Control
                                            className="col-3"
                                            type="number"
                                            disabled
                                            min="0"
                                            value={item.quantity_complementary}
                                          />
                                          <br />
                                          <br />
                                          <br />
                                        </Col>
                                      )
                                    )}
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="rol"
                                >
                                  <Form.Label column sm="2">
                                    N° Baños
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control
                                      disabled
                                      aria-label="numero de baños"
                                      type="number"
                                      size="lg"
                                      placeholder="Ingrese n° baños"
                                      required
                                      value={bathroom}
                                      onChange={(event) => {
                                        setBathroom(event.target.value);
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="bedroom"
                                >
                                  <Form.Label column sm="2">
                                    N° Habitaciones
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control
                                      disabled
                                      aria-label="numero de habitaciones"
                                      type="number"
                                      size="lg"
                                      placeholder="Ingrese n° habitaciones"
                                      required
                                      value={bedroom}
                                      onChange={(event) => {
                                        setBedroom(event.target.value);
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="rol"
                                >
                                  <Form.Label column sm="2">
                                    Rol
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control
                                      disabled
                                      aria-label="ingrese rol"
                                      type="text"
                                      size="lg"
                                      placeholder="Ingrese rol"
                                      required
                                      value={rol}
                                      onChange={(event) => {
                                        setRol(event.target.value);
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  disabled
                                  as={Row}
                                  className="mb-3"
                                  controlId="terrainArea"
                                >
                                  <Form.Label column sm="2">
                                    Superficie en terreno
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control
                                      disabled
                                      type="text"
                                      size="lg"
                                      placeholder="Ingrese superficie terreno"
                                      required
                                      value={terrainArea}
                                      onChange={(event) => {
                                        setTerrainArea(event.target.value);
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="constructionArea"
                                >
                                  <Form.Label column sm="2">
                                    Área en construcción
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control
                                      disabled
                                      type="text"
                                      size="lg"
                                      placeholder="Ingrese área en construcción"
                                      required
                                      value={constructionArea}
                                      onChange={(event) => {
                                        setConstructionArea(event.target.value);
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="descClient"
                                >
                                  <Form.Label column sm="2">
                                    Descripción del bien a tasar
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control
                                      disabled
                                      as="textarea"
                                      type="text"
                                      size="lg"
                                      placeholder="Ingrese descripción del cliente"
                                      required
                                      value={descClient}
                                      onChange={(event) => {
                                        setDescClient(event.target.value);
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Fotos tipo de bien</Accordion.Header>
                  <Accordion.Body>
                    <div className="card">
                      <div className="col-3">
                        <Button
                          id="blob"
                          className="btn btn-outline-primary"
                          onClick={() => fetchPhotos()}
                        >
                          Descargar todas las fotos
                        </Button>
                      </div>
                      <div className="card-body">
                        <h4 className="card-title">Fotos de la propiedad</h4>
                        <hr />
                        <div className="form-wrapper">
                          <Row className="my-3">
                            <Col sm="12">
                              <Form.Group className="mb-3">
                                <Form.Label>Fachada</Form.Label>
                                <br />
                                {namePhoto.length === 0 ? (
                                  <>Cliente no ingresó fotos de Fachada</>
                                ) : (
                                  namePhoto.map((index, item) => (
                                    <Image
                                      key={index}
                                      style={{
                                        height: "300px",
                                        margin: "5px",
                                      }}
                                      className="img-fluid rounded"
                                      src={namePhoto[item]}
                                    />
                                  ))
                                )}
                                <hr />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>Baños</Form.Label>
                                <br />
                                {namePhoto2.length === 0 ? (
                                  <>Cliente no ingresó fotos de Baños</>
                                ) : (
                                  namePhoto2.map((index, item) => (
                                    <Image
                                      key={index}
                                      style={{
                                        height: "300px",
                                        margin: "5px",
                                      }}
                                      className="img-fluid rounded"
                                      src={namePhoto2[item]}
                                    />
                                  ))
                                )}
                                <hr />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>Cocina</Form.Label>
                                <br />
                                {namePhoto3.length === 0 ? (
                                  <>Cliente no ingresó fotos de Cocina</>
                                ) : (
                                  namePhoto3.map((index, item) => (
                                    <Image
                                      key={index}
                                      style={{
                                        height: "300px",
                                        margin: "5px",
                                      }}
                                      className="img-fluid rounded"
                                      src={namePhoto3[item]}
                                    />
                                  ))
                                )}
                                <hr />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>Dormitorio</Form.Label>
                                <br />
                                {namePhoto4.length === 0 ? (
                                  <>Cliente no ingresó fotos de Dormitorio</>
                                ) : (
                                  namePhoto4.map((index, item) => (
                                    <Image
                                      key={index}
                                      style={{
                                        height: "300px",
                                        margin: "5px",
                                      }}
                                      className="img-fluid rounded"
                                      src={namePhoto4[item]}
                                    />
                                  ))
                                )}
                                <hr />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>Sala de estar</Form.Label>
                                <br />
                                {namePhoto5.length === 0 ? (
                                  <>Cliente no ingresó fotos de Sala de estar</>
                                ) : (
                                  namePhoto5.map((index, item) => (
                                    <Image
                                      key={index}
                                      style={{
                                        height: "300px",
                                        margin: "5px",
                                      }}
                                      className="img-fluid rounded"
                                      src={namePhoto5[item]}
                                    />
                                  ))
                                )}
                                <hr />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>Otros</Form.Label>
                                <br />
                                {namePhoto6.length === 0 ? (
                                  <>Cliente no ingresó fotos de Otros</>
                                ) : (
                                  namePhoto6.map((index, item) => (
                                    <Image
                                      key={index}
                                      style={{
                                        height: "300px",
                                        margin: "5px",
                                      }}
                                      className="img-fluid rounded"
                                      src={namePhoto6[item]}
                                    />
                                  ))
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Tipo Documentos</Accordion.Header>
                  <Accordion.Body>
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Tipo de documentos</h4>
                        <Row className="my-3">
                          <div className="col-12 col-sm-12 col-md-6">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="card-title">Escritura</h4>
                                <hr />
                                <div className="form-wrapper">
                                  <Form>
                                    <Col>
                                      <Form.Group className="mb-3">
                                        <Link onClick={() => openInNewTab(doc)}>
                                          {doc.length !== 0 ? (
                                            "Descargar: Escritura"
                                          ) : (
                                            <></>
                                          )}
                                        </Link>
                                      </Form.Group>
                                    </Col>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="card-title">Certificados Sii</h4>
                                <hr />
                                <div className="form-wrapper">
                                  <Form>
                                    <Col>
                                      <Form.Group className="mb-3">
                                        <Link
                                          onClick={() => openInNewTab(doc2)}
                                        >
                                          {doc2.length !== 0 ? (
                                            "Descargar: Certificados Sii"
                                          ) : (
                                            <></>
                                          )}
                                        </Link>
                                      </Form.Group>
                                    </Col>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="card-title">Planos</h4>
                                <hr />
                                <div className="form-wrapper">
                                  <Form>
                                    <Col>
                                      <Form.Group className="mb-3">
                                        <Link
                                          onClick={() => openInNewTab(doc3)}
                                        >
                                          {doc3.length !== 0 ? (
                                            "Descargar: Planos"
                                          ) : (
                                            <></>
                                          )}
                                        </Link>
                                      </Form.Group>
                                    </Col>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="card-title">Otros</h4>
                                <hr />
                                <div className="form-wrapper">
                                  <Form>
                                    <Col>
                                      <Form.Group className="mb-3">
                                        <Link
                                          onClick={() => openInNewTab(doc4)}
                                        >
                                          {doc4.length !== 0 ? (
                                            "Descargar: Otros"
                                          ) : (
                                            <></>
                                          )}
                                        </Link>
                                      </Form.Group>
                                    </Col>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : (
              <div>
                <Spinner animation="border" role="status"></Spinner>
                <span>Cargando datos...</span>
              </div>
            )}
          </div>
          <Button href="/">Regresar a lista de tasaciones</Button>
        </div>
      </div>
    </div>
  );
}