import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import APIRoute from "../routersAPI/routes.json";
import { Spinner } from "react-bootstrap";
export default function CreateClient() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [rut_client, setRutClient] = useState("");
  const [name_client, setNameClient] = useState("");
  const [email_client, setEmailClient] = useState("");
  const [phone_client, setPhoneClient] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [validationError, setValidationError] = useState({});
  const [show, setShow] = useState(0);

  useEffect(() => {
    fetchClient();
  }, [APIRoute.clients + id]);

  const fetchClient = async () => {
    setShow(1);
    await axios.get(APIRoute.clients + id).then(({ data }) => {
      setRutClient(data.client["rut_client"]);
      setNameClient(data.client["name_client"]);
      setPhoneClient(data.client["phone_client"]);
      setEmailClient(data.client["email_client"]);
    });
  };
  const updateClientList = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", id);
    formData.append("rut", rut_client);
    formData.append("name", name_client);
    formData.append("phone", phone_client);
    formData.append("email", email_client);

    await axios
      .post(APIRoute.edit_client + id, formData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        navigate("/client");
      })
      .catch(({ response }) => {
        if (response.status === 500 || 400) {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Actualizar Datos Cliente</h4>
              <hr />
              <div className="form-wrapper">
              {show ? (
                <Form onSubmit={updateClientList}>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="rut_client"
                      >
                        <Form.Label column sm="2">
                          Rut Cliente
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="Ej. 14123456-3"
                            required
                            value={rut_client}
                            onChange={(event) => {
                              setRutClient(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="name_client"
                      >
                        <Form.Label column sm="2">
                          Nombre completo cliente
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="Ingrese nombre cliente"
                            required
                            value={name_client}
                            onChange={(event) => {
                              setNameClient(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="phone_client"
                      >
                        <Form.Label column sm="2">
                          Telefono
                        </Form.Label>
                        <Col sm="10">
                          <Row>
                            <Col sm="2">
                              <Form.Control disabled value="+ 56" size="lg" />
                            </Col>
                            <Col sm="10">
                              <Form.Control
                                type="number"
                                size="lg"
                                placeholder="Ingrese Telefono"
                                required
                                value={phone_client}
                                onChange={(event) => {
                                  setPhoneClient(event.target.value);
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="email_client"
                      >
                        <Form.Label column sm="2">
                          Correo electrónico
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="Ingrese Correo"
                            required
                            value={email_client}
                            onChange={(event) => {
                              setEmailClient(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    variant="primary"
                    className="mt-2"
                    size="lg"
                    block="block"
                    type="submit"
                  >
                    Guardar
                  </Button>
                </Form>
                ) : (
                  <div className="">
                    <Spinner animation="border" role="status">
                    </Spinner>
                    <span className="">Cargando datos...</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
