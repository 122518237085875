import React from "react";
import "./App.css";
import { useState, useEffect } from "react";
import Access from "./components/access/access";
import Authentication from "./components/Auth/Authentication";
import { Button } from "react-bootstrap";

function App() {
  let [status, setStatus] = useState(false);
  let [checkHidden, setCheckHidden] = useState(sessionStorage.getItem('sessionHidden'));

  useEffect(() => {
    if (checkHidden === '1') {
      setCheckHidden(true);
    } else {
      setCheckHidden(false);
    }
  }, []);
  return (
    <>
      {!status ? (
        <>
          <Button
            variant="warning"
            onClick={(e) => { setStatus(!status); }}
            hidden={checkHidden}
            style={{ position: "fixed", right: "5vw", top: "1vh" }}
          >
            <i className="fas fa-lock"></i>
          </Button>
          <Access />
        </>
      ) : (
        <>
          <>
            <Button
              variant="warning"
              onClick={(e) => setStatus(!status)}
              hidden={checkHidden}
              style={{ position: "fixed", right: "5vw", top: "1vh" }}
            >
              <i className="fas fa-user"></i>
            </Button>
          </>
          <Authentication />
        </>
      )}
    </>
  );
}

export default App;